import { AuthState } from '../../../types';

const initialState: AuthState = {
  isAuth: false,
  loading: true,
  user: undefined,
  error: '',
  signupEmail: localStorage.getItem('signupEmail') || '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'auth/login': {
      delete state.error;
      return {
        ...state,
        isAuth: true,
        loading: false,
        user: action.payload,
      };
    }

    case 'auth/load': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'auth/update': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case 'auth/error': {
      return {
        ...state,
        // isAuth: false,
        loading: false,
        error: action.payload,
      };
    }

    case 'auth/verify': {
      return {
        ...state,
        isAuth: true,
        loading: false,
        user: action.payload,
      };
    }

    case 'auth/unverify': {
      return {
        ...state,
        isAuth: false,
        loading: false,
        user: undefined,
      };
    }

    case 'auth/setSignupEmail': {
      return {
        ...state,
        signupEmail: action.payload,
      };
    }

    case 'auth/clearSignupEmail': {
      return {
        ...state,
        signupEmail: '',
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
