// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// // types
// import { Property } from 'csstype';
// import { PaletteMode } from '@material-ui/core';
import { CustomizationState } from '../../../types';

// helpers
import { getThemeFromStorage } from '../../../helpers/storageHelpers';

const initialState: CustomizationState = {
  isOpen: undefined, //for active default menu
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  themeMode: getThemeFromStorage(),
  presetColor: 'links',
  locale: 'es',
  opened: true,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'setMenuOpen':
      return {
        ...state,
        isOpen: action.payload,
      };

    case 'setThemeMode': {
      return {
        ...state,
        themeMode: action.payload,
      };
    }
    case 'setPresetColor': {
      return {
        ...state,
        presetColor: action.payload,
      };
    }
    case 'setLocale': {
      return {
        ...state,
        local: action.payload,
      };
    }
    case 'setSidebarOpened':
      return {
        ...state,
        opened: action.payload,
      };
    case 'setFontFamily': {
      return {
        ...state,
        fontFamily: action.payload,
      };
    }
    case 'setBorderRadius': {
      return {
        ...state,
        borderRadius: action.payload,
      };
    }
    case 'setOutlinedFilled': {
      return {
        ...state,
        outlinedFilled: action.payload,
      };
    }
    case 'resetCustomization': {
      return {
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
