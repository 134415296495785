import { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

// routes
import AuthRouter from './AuthRouter';

import PublicRoute from './guards/PublicRoute';

// redux hooks

// redux actions
import { verifySession } from '../modules/auth/actions/authActions';

// import Loader from '../modules/shared/components/Loader';
import { useDispatch } from 'react-redux';
import { setMenuOpen } from '../modules/shared/actions/customizationActions';
import Cart from '../modules/general/Cart';
import AddToCart from '../modules/general/Cart/AddToCart';
import { useAppSelector } from '../hooks/useRedux';
import LoadingScreen from '../modules/shared/screens/LoadingScreen';
import PrivateRoute from './guards/PrivateRoute';
import GeneralRoutes, { path } from '../modules/general/routes/GeneralRoutes';

// import PrincipalScreen from '../modules/PrincipalScreen';

const AppRouter = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);

  const token = localStorage.getItem('x-token');
  useEffect(() => {
    dispatch(verifySession());
    dispatch(setMenuOpen(location.pathname));
  }, [dispatch, location.pathname, token]);

  return (
    <>
      {auth.loading && <LoadingScreen />}
      {!auth.loading && (
        <>
          <Switch>
            <Route
              exact
              path="/addToCart/:prod_id/:plan_id/:opci_pago_id/:carri_cantidad"
              component={AddToCart}
            />
            <Route exact path="/carrito" component={Cart} />
            <PublicRoute
              component={AuthRouter}
              path={['/login', '/registro', '/recuperar-clave', '/restablecer-clave/:token']}
            />
            <PrivateRoute path={path} component={GeneralRoutes} />
            <Redirect to="/login" />
          </Switch>
        </>
      )}
    </>
  );
};

export default AppRouter;
