import { Checkbox, FormHelperText, Grid, TextField, MenuItem, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import LoadingScreen from '../../../../shared/screens/LoadingScreen';
import { mainApi } from '../../../../../api/mainApi';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { User } from '../../../../auth/interfaces/login.interfaces';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { updateUserDataFact } from '../../../../auth/actions/authActions';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

const rucRegex = new RegExp('[0-9]{10}001');
const phoneRegex = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');

const validationSchema = Yup.object().shape({
  tipo_identi_id: Yup.number().required('El tipo de identificación es requerido'),
  clie_fact_razon_social: Yup.string().when('tipo_identi_id', {
    is: 2,
    then: (val) => val.required('La razón social es requerida'),
  }),
  clie_fact_ruc: Yup.string()
    .matches(rucRegex, 'Ingrese un RUC valido')
    .when('tipo_identi_id', {
      is: 2,
      then: (val) => val.required('El RUC es requerido'),
    }),
  clie_fact_nomb_comer: Yup.string().when('tipo_identi_id', {
    is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
    then: (val) => val.required('La razón social es requerida'),
  }),
  clie_fact_dire: Yup.string().when('tipo_identi_id', {
    is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
    then: (val) => val.required('La dirección es requerida'),
  }),
  clie_fact_tele: Yup.string()
    .matches(
      phoneRegex,
      'El numero de teléfono o celular no puede contener letras o caracteres especiales'
    )
    .when('tipo_identi_id', {
      is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
      then: (val) => val.required('El número de teléfono es requerido'),
    })
    .nullable(),
  clie_fact_correo: Yup.string()
    .email('Debe ingresar un correo válido')
    .when('tipo_identi_id', {
      is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
      then: (val) => val.required('El correo es requerido'),
    }),
  clie_fact_tipo_id: Yup.number().when('tipo_identi_id', {
    is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
    then: (val) => val.required('El tipo de identificación es requerido'),
  }),
  clie_fact_contri_espe: Yup.string(),
});

const DatosFacturacion = () => {
  const dispatch = useAppDispatch();

  const user: User = useAppSelector((state) => state.auth.user);
  const error: string = useAppSelector((state) => state.auth.error);

  const { values, getFieldProps, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      tipo_identi_id: user.tipo_identi_id,
      clie_fact_razon_social: user.clie_fact_razon_social || '',
      clie_fact_ruc: user.clie_fact_ruc || '',
      clie_fact_nomb_comer: user.clie_fact_nomb_comer || '',
      clie_fact_dire: user.clie_fact_dire || '',
      clie_fact_tele: user.clie_fact_tele || '',
      clie_fact_correo: user.clie_fact_correo || '',
      clie_fact_tipo_id: user.clie_fact_tipo_id || '',
      clie_fact_contri_espe: user.clie_fact_contri_espe || '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateUserDataFact(values));
    },
  });

  const [select, setSelect] = useState({
    loading: true,
    listaTipoFact: [],
  });

  useEffect(() => {
    mainApi.get('/listTpClienteDtFactura').then((res) => {
      if (res?.data) {
        setSelect((prev) => ({ ...prev, listaTipoFact: res.data, loading: false }));
      }
    });
  }, []);

  return (
    <>
      {select.loading ? (
        <LoadingScreen />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
            {error && (
              <Grid item xs={12}>
                <ErrorAlert message={error} />
              </Grid>
            )}

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_razon_social">Razón social</InputLabel>
              <TextField
                error={Boolean(errors.clie_fact_razon_social)}
                id="clie_fact_razon_social"
                fullWidth
                {...getFieldProps('clie_fact_razon_social')}
              />
              {errors.clie_fact_razon_social && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_razon_social}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_ruc">RUC</InputLabel>
              <TextField
                error={Boolean(errors.clie_fact_ruc)}
                id="clie_fact_ruc"
                fullWidth
                {...getFieldProps('clie_fact_ruc')}
              />
              {errors.clie_fact_ruc && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_ruc}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="clie_fact_nomb_comer">Nombre comercial</InputLabel>
              <TextField
                error={Boolean(errors.clie_fact_nomb_comer)}
                id="clie_fact_nomb_comer"
                fullWidth
                {...getFieldProps('clie_fact_nomb_comer')}
              />
              {errors.clie_fact_nomb_comer && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_nomb_comer}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_dire">Dirección</InputLabel>
              <TextField fullWidth {...getFieldProps('clie_fact_dire')} />
              {errors.clie_fact_dire && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_dire}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_tele">Teléfono</InputLabel>
              <TextField
                error={Boolean(errors.clie_fact_tele)}
                id="clie_fact_tele"
                fullWidth
                {...getFieldProps('clie_fact_tele')}
                inputProps={{ maxLength: 10 }}
              />
              {errors.clie_fact_tele && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_tele}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_correo">Correo</InputLabel>
              <TextField
                error={Boolean(errors.clie_fact_correo)}
                id="clie_fact_correo"
                fullWidth
                {...getFieldProps('clie_fact_correo')}
              />
              {errors.clie_fact_correo && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_correo}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="clie_fact_tipo_id">Figura legal</InputLabel>
              <TextField
                select
                error={Boolean(errors.clie_fact_tipo_id)}
                id="clie_fact_tipo_id"
                {...getFieldProps('clie_fact_tipo_id')}
                fullWidth
              >
                {select.listaTipoFact.map((item: any) => (
                  <MenuItem key={item.clie_fact_tipo_id} value={item.clie_fact_tipo_id}>
                    {item.clie_fact_tipo_deta}
                  </MenuItem>
                ))}
              </TextField>
              {errors.clie_fact_tipo_id && (
                <FormHelperText error id="usernameError">
                  {errors.clie_fact_tipo_id}
                </FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} alignItems="center">
              <Grid item>
                <InputLabel htmlFor="clie_fact_contri_espe">Contribuyente especial</InputLabel>
              </Grid>
              <Grid item>
                <Checkbox
                  id="clie_fact_contri_espe"
                  checked={values.clie_fact_contri_espe === 'N'}
                  onChange={({ target }) =>
                    setFieldValue('clie_fact_contri_espe', target.checked ? 'N' : '')
                  }
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
              <Grid item>
                <Button type="submit" variant="contained" color="secondary" sx={{ px: 4 }}>
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default DatosFacturacion;
