import { useHistory } from 'react-router-dom';
import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import logo from '../../../../assets/images/paylinks-logo-blanco.png';
import CartItem from './CartItem';
import { useAppSelector } from '../../../../hooks/useRedux';
import Auth from './Auth';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#e1e1e1',
  },
  item: {
    backgroundColor: '#fff',
    borderRadius: '12px',
  },
}));

const NoAuthCart = () => {
  const classes = useStyles();

  const items = useAppSelector((state) => state.cart.items);
  const history = useHistory();

  if (items.length === 0) {
    history.push('/login');
  }

  return (
    <>
      <Grid item container justifyContent="center" xs={10} md={7}>
        <img src={logo} alt="" style={{ maxWidth: '400px' }} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Card>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            p={3}
            className={classes.header}
            sx={{ display: items.length > 0 ? 'flex' : 'none' }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Tu orden está lista</Typography>
            </Grid>
            {items.map((item: any, index: number) => (
              <Grid item xs={12} key={index} mt={2} p={2} className={classes.item}>
                <CartItem cartItem={item} />
              </Grid>
            ))}
          </Grid>
          <Grid>
            <Auth />
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default NoAuthCart;
