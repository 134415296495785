import { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';

// redux hooks
import { useAppSelector } from '../../hooks/useRedux';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
}

const PublicRoute = ({ component: Component, ...rest }: Props) => {
  const { isAuth } = useAppSelector((state) => state.auth);

  const { pathname } = useLocation();

  const isInAuthRoutes =
    pathname.includes('/login') || pathname === '/registro' || pathname === '/';

  const lastPath = localStorage.getItem('lastPath') || '/carrito';

  //console.log('auth');

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: isInAuthRoutes ? lastPath : pathname }} />
        )
      }
    />
  );
};

export default PublicRoute;
