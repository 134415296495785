import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';

const TermsDialog = () => {
  const isOpen = useAppSelector((state) => state.terms.open);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch({ type: 'setOpenTerms', payload: false });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle>
        <Typography component="span" sx={{ fontSize: '2em', fontWeight: 600 }}>
          Términos y condiciones
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">ACEPTO TÉRMINOS Y CONDICIONES</Typography>
        <Typography variant="body2">
          Sí, acepto la creación de mi cuenta, recibir notificaciones, visualizar contenidos,
          información comercial relacionados con la marca, productos y segmentos informativos de
          Links de acuerdo con la política de privacidad que ya he leído y acepto.
        </Typography>

        <Typography variant="h4" mt={2}>
          POLÍTICA DE PRIVACIDAD
        </Typography>
        <Typography variant="body2">
          La presente Política de Privacidad establece los términos en que <strong>Links</strong>{' '}
          usa y protege la información que es proporcionada por sus usuarios al momento de utilizar
          su sitio web y aplicativos móviles. Esta compañía está comprometida con la seguridad de
          los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con
          la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de
          acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede
          cambiar con el tiempo o ser actualizada, por lo que, le recomendamos y enfatizamos revisar
          continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
        </Typography>

        <Typography variant="h4" mt={2}>
          Información que es recogida
        </Typography>
        <Typography variant="body2">
          Nuestro sitio web y aplicativos móviles podrán recoger información personal, por ejemplo:
          Nombre, información de contacto como su dirección de correo electrónico e información
          demográfica. Así mismo cuando sea necesario podrá ser requerida información específica
          para procesar algún pedido o realizar una entrega o facturación.
        </Typography>

        <Typography variant="h4" mt={2}>
          Uso de la información recogida
        </Typography>
        <Typography variant="body2">
          Nuestro sitio web y aplicativos móviles emplean la información con el fin de proporcionar
          el mejor servicio posible, particularmente para mantener un registro de usuarios, de
          pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible que sean
          enviados correos electrónicos periódicamente con información de ofertas especiales, nuevos
          productos y otra información publicitaria que consideremos relevante para usted o que
          pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección
          que usted proporcione y podrán ser cancelados en cualquier momento.
        </Typography>
        <Typography variant="body2">
          Así mismo, la información recogida puede ser compartida con terceros acorde con los
          intereses seleccionados de la persona y utilizada con el fin de impactar a usuarios con
          promociones, activaciones y publicidades seleccionados de las marcas.
        </Typography>
        <Typography variant="body2">
          <strong>Redlinks</strong> está altamente comprometido para cumplir con el compromiso de
          mantener su información segura. Usamos los sistemas más avanzados y los actualizamos
          constantemente para asegurarnos que no exista ningún acceso no autorizado.
        </Typography>

        <Typography variant="h4" mt={2}>
          Mayores de edad
        </Typography>
        <Typography variant="body2">
          En el supuesto de ser mayor de edad, es decir 18 años en adelante, podrás registrarte e
          ingresar en el App Móvil de <strong>Redlinks</strong> sin necesidad del consentimiento
          previo de tus padres o tutores.
        </Typography>

        <Typography variant="h4" mt={2}>
          ¿Qué ocurre en el caso de que seas menor de 18 años?
        </Typography>
        <Typography variant="body2">
          En este supuesto, será condición obligatoria el consentimiento de tus padres o tutores
          para que podamos tratar sus datos personales.
        </Typography>
        <Typography variant="body2">
          Advertencia: Si tienes menos de 18 años, no debes registrarte si no has obtenido el
          consentimiento de tus padres, en caso de que esta política sea incumplida procederemos a
          denegar o anular tu solicitud en caso de tener constancia de ello
        </Typography>

        <Typography variant="h4" mt={2}>
          Cookies
        </Typography>
        <Typography variant="body2">
          Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso
          para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve
          entonces para tener información respecto al tráfico web, y también facilita las futuras
          noticias recurrentes. Otra función que tienen las cookies es que con ellas la web pueden
          reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su
          web.
        </Typography>
        <Typography variant="body2">
          Nuestras apps móviles emplean cookies para poder identificar las páginas que son visitadas
          y su frecuencia. Esta información es empleada únicamente para análisis estadístico. Usted
          puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo, las
          cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a
          información de su ordenador ni de usted, a menos de que usted así lo quiera y la
          proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la
          mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor
          servicio web. También usted puede cambiar la configuración de su ordenador para declinar
          las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros
          servicios.
        </Typography>

        <Typography variant="h4" mt={2}>
          Enlaces a Terceros
        </Typography>
        <Typography variant="body2">
          Nuestro sitio web y app móviles pudieran contener enlaces a otros sitios que pudieran ser
          de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no
          tenemos control sobre el sitio al que es redirigido y por lo tanto no somos responsables
          de los términos o privacidad ni de la protección de sus datos en esos otros sitios
          terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es
          recomendable que los consulte para confirmar que usted está de acuerdo con estas.
        </Typography>

        <Typography variant="h4" mt={2}>
          Control de su información personal
        </Typography>
        <Typography variant="body2">
          En cualquier momento usted puede restringir la recopilación o el uso de la información
          personal que es proporcionada a nuestro sitio web. Cuando se le solicite rellenar un
          formulario, como el de registro de usuario usted puede marcar o desmarcar la opción para
          aceptar las condiciones del servicio. En caso de recibir información por correo
          electrónico, boletines o publicidad usted puede cancelarla en cualquier momento dando clic
          en el enlace para cancelar su suscripción.
        </Typography>
        <Typography variant="body2">
          Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada
          sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.
        </Typography>
        <Typography variant="body2">
          <strong>Links</strong> Se reserva el derecho de cambiar los términos de la presente
          Política de Privacidad en cualquier momento.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default TermsDialog;
