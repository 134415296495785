import { Grid, ListItem, List, Paper, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import DatosFacturacion from './DatosFacturacion';
import DatosPersonales from './DatosPersonales';
import PasswordChange from './PasswordChange';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: '1em',
  },
}));

const items = [
  { label: 'Datos personales', component: <DatosPersonales /> },
  { label: 'Datos de facturación', component: <DatosFacturacion /> },
  { label: 'Cambiar contraseña', component: <PasswordChange /> },
];

const getActiveItem = (activeItem: number) =>
  items.find((item, index) => index === activeItem)?.component;

const EditarDatos = () => {
  const classes = useStyles();

  const [activeItem, setActiveItem] = useState(0);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <List>
            {items.map((item, index) => (
              <ListItem
                key={index}
                className={classes.listItem}
                button
                selected={activeItem === index}
                onClick={() => setActiveItem(index)}
              >
                {item.label}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={8}>
          {getActiveItem(activeItem)}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EditarDatos;
