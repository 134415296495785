import { CartState } from '../../../types';

const initialState: CartState = {
  loading: true,
  items: JSON.parse(localStorage.getItem('cart') || '[]'),
  prices: {},
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'cart/load':
      return { ...state, loading: true };
    case 'cart/stop-load':
      return {
        ...state,
        loading: false,
      };
    case 'cart/set-items':
      return {
        ...state,
        items: action.payload,
      };
    case 'cart/add':
      return { ...state, loading: false, items: [...state.items, action.payload] };

    case 'cart/update':
      return {
        ...state,
        loading: false,
        items: state.items.map((item) =>
          // eslint-disable-next-line eqeqeq
          item.prod_id == action.payload.prod_id ? action.payload : item
        ),
      };

    case 'cart/remove':
      const prices = state.prices;
      delete prices[action.payload.prod_id];

      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item.prod_id !== action.payload),
        prices: {
          ...prices,
          [action.payload]: 0,
        },
      };

    case 'cart/set-price':
      return {
        ...state,
        prices: {
          ...state.prices,
          [action.payload.item.prod_id]: action.payload.price,
        },
      };

    case 'cart/clear':
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
};

export default reducer;
