import axios from 'axios';

const urlWeb = `https://api-pagos.links.com.ec`;

const baseUrl = `https://api-pagos.links.com.ec/api`;

const mainApi = axios.create({
  baseURL: baseUrl,
});

mainApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('x-token');
      localStorage.removeItem('user');
      window.location.replace('login');
    }
  }
);
mainApi.interceptors.request.use((config) => {
  // config.headers['X-CSRF-TOKEN'] = 'JgiFyadLJ9mONAJMg3dSVc6SoE6YBDThnoV3IbSB';
  const token = localStorage.getItem('x-token');
  config.headers.Authorization = token ? token : '';
  return config;
});

export { mainApi, baseUrl, urlWeb };
