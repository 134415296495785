import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Fab, Toolbar, useMediaQuery } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

// third-party
import clsx from 'clsx';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
// import navigation from "./../../menu-items";
import { drawerWidth } from '../../constants/uiConstants';
import { setSidebarOpened } from '../../actions/customizationActions';
import TermsDialog from '../../components/TermsDialog';

// assets
// import { IconChevronRight } from "@tabler/icons";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle left drawer
  const leftDrawerOpened = useAppSelector((state) => state.customization?.opened);
  const dispatch = useAppDispatch();

  const handleLeftDrawerToggle = () => {
    // dispatch({ type: SET_MENU, opened: !leftDrawerOpened });

    dispatch(setSidebarOpened(!leftDrawerOpened));
  };

  React.useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    localStorage.setItem('logoPath', 'http://api.web.educalinks.com.ec/storage/Web_legacy/2.png');
    dispatch(setSidebarOpened(!matchDownMd));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <>
      {!matchDownSm && (
        <Fab
          color="secondary"
          aria-label="ayuda"
          variant="extended"
          sx={{
            position: 'fixed',
            bottom: '30px',
            left: '30px',
            zIndex: 100000,
            color: '#1EE5D5',
            fontWeight: 'bold',
          }}
        >
          <HelpIcon />
          &nbsp; Ayuda
        </Fab>
      )}
      <div className={classes.root}>
        <CssBaseline />
        {/* header */}
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

        {/* main content */}
        <main
          className={clsx([
            classes.content,
            {
              [classes.contentShift]: leftDrawerOpened,
            },
          ])}
        >
          {/* breadcrumb */}
          {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
          {children}
        </main>
        {/* <Customization /> */}
      </div>
      <TermsDialog />
    </>
  );
};

export default MainLayout;
