import { Alert } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

const ErrorAlert = ({
  message,
  handleDismiss,
}: {
  message: string;
  handleDismiss?: () => void;
}) => {
  return (
    <Alert icon={false} severity="error" style={{ marginBlockEnd: 32 }}>
      {message}
    </Alert>
  );
};

export default ErrorAlert;
