import {
  Breadcrumbs,
  Paper,
  Typography,
  Link,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ChangeEvent, createContext, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import CartTable from './CartTable';
import { showAlert, showConfirmationAlert } from '../../../../helpers/alerts';
import { clearCart, deleteItem } from '../../../shared/actions/cartActions';
import { CartItem } from '../../../../types';
import DatosFacturacion from './DatosFacturacion';
import { User } from '../../../auth/interfaces/login.interfaces';
import { getCheckout, guardarPago, guardarPagoDepositTransfer } from '../../../../helpers/checkout';
import time from '../../../../helpers/time';

import checkHex from '../../../../assets/images/check-hex.png';
import NoItems from './NoItems';
import Pago from './Pago';
import CartGrid from './CartGrid';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    color: theme.palette.text.secondary,
    width: 'auto',
    fontSize: '1.5em',

    '& $alternativeLabel': {
      marginTop: 0,
    },
    '& .MuiStepLabel-label': {
      color: '#e1e1e1',
      fontSize: '.8em',
    },
    '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-completed': {
      color: `${theme.palette.secondary.main} !important`,
      fontWeight: 'bold',
    },
  },
  step: {
    '& .MuiStepIcon-text': {
      fontSize: '.6em',
      fontWeight: 'bold',
    },
    '& $completed': {
      border: 'none',
      color: theme.palette.secondary.main,
    },
    '& $active': {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: 'white',
      '& .MuiStepIcon-text': {
        fill: theme.palette.secondary.main,
      },
    },
    '& $disabled': {
      border: 'none',
      color: 'red',
    },
  },
  stepIcon: {
    color: 'white',
    border: `2px solid #e1e1e1`,
    borderRadius: '50%',
    '& .MuiStepIcon-text': {
      fill: '#e1e1e1',
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {
    color: 'red',
  },
  bar: {
    background: theme.palette.secondary.main,
    position: 'sticky',
    bottom: '20px',
  },
}));

const steps = ['Carrito', 'Facturación', 'Pago'];
export const PayContext = createContext({});

const AuthCart = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [pagado, setPagado] = useState(false);

  const items = useAppSelector((state) => state.cart.items);
  const prices = useAppSelector((state) => state.cart.prices);
  const pricesArray = Object.values(prices) as number[];
  const total = pricesArray.reduce((acc, cur) => acc + cur, 0);

  const user: User = useAppSelector((state) => state.auth.user);

  const [datosFact, setDatosFact] = useState({
    ...user,
  });
  const [tipoPago, setTipoPago] = useState(0);
  const [formaPago, setFormaPago] = useState(0);
  const [fotoTransferDeposit, setFotoTransferDeposit] = useState(null);
  const [dataPagoBanco, setDataPagoBanco] = useState({
    banco_id: '',
    num_comprobante: '',
  });

  // eslint-disable-next-line eqeqeq
  const isHabilitadoDepositTransfer = items.length === 1 && items[0].prod_id == 5;

  const handleDataFactChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = target;
    setDatosFact((state) => ({ ...state, [id]: value }));
  };

  const handleItemDelete = (item: CartItem) => {
    showConfirmationAlert('Aviso', '¿Está seguro que desea eliminar?').then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteItem(item));
        showAlert('¡Listo!', 'El item se ha eliminado correctamente', 'success', false);
      }
    });
  };

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return matchDownSM ? (
          <CartGrid cartItems={items} handleItemDelete={handleItemDelete} />
        ) : (
          <CartTable cartItems={items} handleItemDelete={handleItemDelete} />
        );
      case 1:
        return (
          <DatosFacturacion
            datosFact={datosFact}
            handleDataFactChange={handleDataFactChange}
            setDatosFact={setDatosFact}
          />
        );
      case 2:
        return (
          <Pago
            tipoPago={tipoPago}
            setTipoPago={setTipoPago}
            formaPago={formaPago}
            setFormaPago={setFormaPago}
            fotoTransferDeposit={fotoTransferDeposit}
            setFotoTransferDeposit={setFotoTransferDeposit}
          />
        );
      default:
        return;
    }
  };

  const handlePay = () => {
    const PaymentCheckout = getCheckout((response) => {
      guardarPago(user.clie_id, response, datosFact, 1).then((result) => {
        if (result?.isConfirmed) {
          dispatch(clearCart());
          setPagado(true);
        } else {
          setActiveStep(activeStep - 1);
        }
      });
    });

    const tax_amount = +(total / 1.12).toFixed(2);
    const iva = +(tax_amount * 0.12).toFixed(2);

    PaymentCheckout.open({
      user_id: `${user.clie_id}`, //id delcliente
      user_email: user.clie_correo, //optional
      user_phone: user.clie_tele || '', //optional
      order_description: 'Pago web desde links.com.ec', //descripcion
      order_amount: total, //valor a cobrar
      order_vat: iva, //valor iva
      order_reference: `${user.clie_id};${time().format('YYYY/MM/DD').replaceAll('/', '')}`, //numero de codigo de compra (idCliente + fecha)
      order_installments_type: tipoPago,
      order_taxable_amount: tax_amount, // optional: Only available for Datafast (Equador). The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
      order_tax_percentage: 12, // optional: Only available for Datafast (Equador). The tax percentage to be applied to this order.
    });
  };

  const handlePayDepositTransfer = () => {
    if (
      !fotoTransferDeposit ||
      !dataPagoBanco.banco_id ||
      (formaPago === 1 && !dataPagoBanco.num_comprobante)
    ) {
      showAlert('Aviso', 'Debe completar todos los campos', 'warning', false);
    } else {
      guardarPagoDepositTransfer(
        user.clie_id,
        formaPago,
        datosFact,
        dataPagoBanco.banco_id,
        dataPagoBanco.num_comprobante,
        fotoTransferDeposit
      ).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearCart());
          setPagado(true);
        }
      });
    }
  };

  // const handleDepositOrTransfer = () => {};

  const handleClick = () => {
    // eslint-disable-next-line eqeqeq
    if (activeStep === 2 && isHabilitadoDepositTransfer && formaPago == 0) {
      showAlert('¡Atención!', 'Debe seleccionar una forma de pago', 'warning', false);
    } else if (activeStep === 2 && (!isHabilitadoDepositTransfer || formaPago === 3)) {
      handlePay();
    } else if (
      activeStep === 2 &&
      isHabilitadoDepositTransfer &&
      (formaPago === 1 || formaPago === 2)
    ) {
      handlePayDepositTransfer();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" color="secondary" />}>
          <Link component={RouterLink} to="/" underline="hover" color="black">
            Inicio
          </Link>
          <Typography color="secondary" variant="h5">
            Carrito
          </Typography>
        </Breadcrumbs>
      </Paper>
      <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
        {items.length > 0 ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3">Proceso de pago</Typography>
              </Grid>
              <Grid item xs={12} mt={1} hidden={activeStep === 0}>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBack sx={{ fontSize: '2em' }} />}
                  sx={{ fontWeight: 600, fontSize: '1.2em' }}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  &nbsp;&nbsp;Atrás
                </Button>
              </Grid>
              <Grid item xs={12} my={2}>
                {!matchDownSM && (
                  <Stepper
                    activeStep={activeStep}
                    hidden={matchDownSM}
                    sx={{ mb: 3 }}
                    orientation={matchDownSM ? 'vertical' : 'horizontal'}
                  >
                    {steps.map((label, index) => (
                      <Step key={index} classes={{ root: classes.step }}>
                        <StepLabel
                          classes={{
                            alternativeLabel: classes.alternativeLabel,
                            labelContainer: classes.labelContainer,
                          }}
                          StepIconProps={{
                            classes: {
                              root: classes.stepIcon,
                              completed: classes.completed,
                              active: classes.active,
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
                <PayContext.Provider
                  value={{
                    tipoPago,
                    setTipoPago,
                    formaPago,
                    setFormaPago,
                    fotoTransferDeposit,
                    setFotoTransferDeposit,
                    dataPagoBanco,
                    setDataPagoBanco,
                    isHabilitadoDepositTransfer,
                  }}
                >
                  {getStepContent(activeStep)}
                </PayContext.Provider>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                justifyContent={matchDownSM ? 'center' : 'flex-end'}
                alignItems="center"
                p={2}
                borderRadius={2}
                className={classes.bar}
                xs={12}
                mt={3}
              >
                <Grid item>
                  <Typography variant="h6" color="white">
                    Total seleccionado:
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography variant="h5" color="white">
                    $ {total.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Button
                    onClick={handleClick}
                    sx={{
                      color: theme.palette.secondary.main,
                      background: 'white',
                      px: 2,
                      '&:hover': {
                        color: theme.palette.secondary.main,
                        background: '#cdcdcd',
                      },
                    }}
                  >
                    {activeStep === steps.length - 1 &&
                    // eslint-disable-next-line eqeqeq
                    (!isHabilitadoDepositTransfer || formaPago != 0)
                      ? 'Pagar'
                      : 'Siguiente'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : items.length === 0 && pagado ? (
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            py={16}
          >
            <Grid item>
              <img src={checkHex} alt="" />
            </Grid>
            <Grid item mt={2}>
              <Typography variant="h2" color="secondary">
                Tu pago ha sido efectuado con éxito
              </Typography>
            </Grid>
            <Grid item mt={2}>
              <Typography
                variant="h3"
                color="primary"
                component={RouterLink}
                to="/transacciones?v=pagos"
              >
                Ir al historial de pagos
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <NoItems />
        )}
      </Paper>
    </>
  );
};

export default AuthCart;
