import { Button, Grid, Typography } from '@material-ui/core';
import agregaCarritoIcon from '../../../../../assets/images/agrega-al-carrito.png';

const NoItems = () => {
  return (
    <Grid container justifyContent="center" p={10} spacing={1}>
      <Grid item container justifyContent="center" xs={12} md={7}>
        <img src={agregaCarritoIcon} alt="" width="200" />
      </Grid>
      <Grid item xs={12} md={7} justifyContent="center" textAlign="center" mt={2}>
        <Typography variant="h4" fontWeight={700}>
          ¡Está vacío!
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} textAlign="center">
        <Typography variant="body1">Aún no has agregado ningún producto</Typography>
      </Grid>
      <Grid item xs={12} md={7} textAlign="center" mt={4}>
        <Button variant="contained" color="secondary" component="a" href="#" target="_blank">
          Ir a la tienda virtual
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoItems;
