import Swal from 'sweetalert2';

type IconTypes = 'success' | 'error' | 'warning' | 'info' | 'question';

export const showConfirmationAlert = (title: string, text: string) =>
  Swal.fire({
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonText: 'Si',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#673ab7',
    confirmButtonColor: '#2196f3',
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

export const dismissLoadingAlert = () => Swal.close();

export const showLoadingAlert = (title = 'Cargando...') => {
  Swal.fire({
    title: title,
    html: `<progress value="10" max="100"></progress>`,
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    currentProgressStep: 1,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  let progreso = 10;

  const progressInterval = setInterval(() => {
    progreso += 10;
    Swal.update({
      html: `<progress value="${progreso}" max="100"></progress>`,
      showConfirmButton: false,
    });
    if (progreso === 90) {
      clearInterval(progressInterval);
    }
  }, 100);
};

export const showAlert = (
  title: string,
  text: string,
  type: IconTypes,
  timeout: boolean = true
) => {
  Swal.update({
    html: `<progress value="100" max="100"></progress>`,
  });
  setTimeout(
    () => {
      Swal.close();

      Swal.fire({
        title: title,
        text: text,
        icon: type,
        html: ``,
        confirmButtonColor: '#2196f3',
      });
    },
    timeout ? 2000 : 0
  );
};
