import { FormHelperText, Grid, MenuItem, TextField, Button } from '@material-ui/core';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validarCedula } from '../../../../../helpers/validations';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { updateUser } from '../../../../auth/actions/authActions';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

const idRegex = new RegExp('[0-9]{10}');
const phoneRegex = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
const onlyLetters = new RegExp(
  '^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$'
);

const validationSchema = Yup.object().shape({
  clie_nomb: Yup.string()
    .max(100, 'Se admiten un máximo de 100 caracteres')
    .required('Los nombres son requeridos')
    .matches(onlyLetters, 'No se pueden ingresar números o caracteres especiales'),
  clie_apell: Yup.string()
    .max(100, 'Se admiten un máximo de 100 caracteres')
    .required('Los apellidos son requeridos')
    .matches(onlyLetters, 'No se pueden ingresar números o caracteres especiales'),
  clie_cedu: Yup.string()
    .when('tipo_identi_id', {
      is: (tipo_identi_id: number) => tipo_identi_id === 1 || tipo_identi_id === 2,
      then: (val) =>
        val
          .required('El número de cédula es requerido')
          .matches(idRegex, 'Ingrese un número de cédula válido')
          .min(10, 'El número de cédula debe tener 10 dígitos')
          .max(10, 'El número de cédula debe tener 10 dígitos')
          .test('clie_cedu', 'Ingrese un número de cédula válido', (val) =>
            validarCedula(val as string)
          ),
    })
    .when('tipo_identi_id', {
      is: (tipo_identi_id: number) => tipo_identi_id === 3,
      then: (val) => val.required('El pasaporte es requerido'),
    }),
  clie_correo: Yup.string()
    .email('Debe ingresar un correo válido')
    .max(100, 'Se admiten un máximo de 100 caracteres')
    .required('El correo es requerido'),
  clie_tele: Yup.string()
    .required('El número de celular es requerido')
    .matches(
      phoneRegex,
      'El numero de teléfono o celular no puede contener letras o caracteres especiales'
    )
    .min(7, 'Ingrese un número de teléfono o celular válido')
    .nullable(),
  clie_dire: Yup.string().required('La dirección es requerida'),
  clie_tipo_id: Yup.number().required('El tipo de identificación es requerido'),
  clie_estado: Yup.number().required('El estado es requerido'),
  tipo_identi_id: Yup.number().required('El tipo de identificación es requerido'),
  password: Yup.string(),
});

const idTypes = [
  { value: 1, label: 'Cédula de identidad' },
  { value: 2, label: 'RUC' },
  { value: 3, label: 'Pasaporte' },
];

const DatosPersonales = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const error: string = useAppSelector((state) => state.auth.error);

  const { getFieldProps, errors, values, touched, handleSubmit } = useFormik({
    initialValues: {
      clie_nomb: user.clie_nomb || '',
      clie_apell: user.clie_apell || '',
      clie_cedu: user.clie_cedu || '',
      clie_correo: user.clie_correo || '',
      clie_tele: user.clie_tele || '',
      clie_dire: user.clie_dire || '',
      clie_tipo_id: user.clie_tipo_id || '',
      clie_estado: user.clie_estado || '',
      tipo_identi_id: user.tipo_identi_id || '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateUser(values));
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
          {error && (
            <Grid item xs={12}>
              <ErrorAlert message={error} />
            </Grid>
          )}
          <Grid item xs={6}>
            <InputLabel htmlFor="tipo_usuario_id">Tipo de identificación</InputLabel>
            <TextField select fullWidth {...getFieldProps('tipo_identi_id')} disabled>
              {idTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="clie_cedu">
              {values.tipo_identi_id !== 3 ? 'Cédula' : 'Pasaporte'}
            </InputLabel>
            <TextField
              error={Boolean(errors.clie_cedu && touched.clie_cedu)}
              id="clie_cedu"
              fullWidth
              {...getFieldProps('clie_cedu')}
              disabled
            />
            {errors.clie_cedu && touched.clie_cedu && (
              <FormHelperText error id="usernameError">
                {errors.clie_cedu}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="clie_nomb">Nombres</InputLabel>
            <TextField
              error={Boolean(errors.clie_nomb && touched.clie_nomb)}
              id="clie_nomb"
              fullWidth
              {...getFieldProps('clie_nomb')}
              disabled
            />
            {errors.clie_nomb && touched.clie_nomb && (
              <FormHelperText error id="usernameError">
                {errors.clie_nomb}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="clie_apell">Apellidos</InputLabel>
            <TextField
              error={Boolean(errors.clie_apell && touched.clie_apell)}
              id="clie_apell"
              fullWidth
              {...getFieldProps('clie_apell')}
              disabled
            />
            {errors.clie_apell && touched.clie_apell && (
              <FormHelperText error id="usernameError">
                {errors.clie_apell}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="clie_correo">Correo</InputLabel>
            <TextField
              type="email"
              error={Boolean(errors.clie_correo && touched.clie_correo)}
              id="clie_correo"
              fullWidth
              {...getFieldProps('clie_correo')}
            />
            {errors.clie_correo && touched.clie_correo && (
              <FormHelperText error id="usernameError">
                {errors.clie_correo}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="clie_tele">Telefono / Celular</InputLabel>
            <TextField
              error={Boolean(errors.clie_tele && touched.clie_tele)}
              id="clie_tele"
              fullWidth
              {...getFieldProps('clie_tele')}
              inputProps={{ maxLength: 10 }}
            />
            {errors.clie_tele && touched.clie_tele && (
              <FormHelperText error id="usernameError">
                {errors.clie_tele}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="clie_dire">Dirección</InputLabel>
            <TextField
              error={Boolean(errors.clie_dire && touched.clie_dire)}
              id="clie_dire"
              fullWidth
              {...getFieldProps('clie_dire')}
            />
            {errors.clie_dire && touched.clie_dire && (
              <FormHelperText error id="usernameError">
                {errors.clie_dire}
              </FormHelperText>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item>
              <Button type="submit" variant="contained" color="secondary" sx={{ px: 4 }}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default DatosPersonales;
