import { Breadcrumbs, Grid, Paper, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getMyProducts } from '../../../helpers/products';
import { useAppSelector } from '../../../hooks/useRedux';
import { ProductItem } from '../../../types';
import { User } from '../../auth/interfaces/login.interfaces';
import LoadingScreen from '../../shared/screens/LoadingScreen';
import NoItems from './NoItems';
import Product from './Product';

const Summary = () => {
  const user: User = useAppSelector((state) => state.auth.user);

  const [products, setProducts] = useState<{ loading: boolean; data: ProductItem[] }>({
    loading: true,
    data: [],
  });

  useEffect(() => {
    getMyProducts(user.clie_id).then((products) => {
      setProducts((prev) => ({
        ...prev,
        loading: false,
        data: products,
      }));
    });
  }, [user.clie_id]);

  const { data, loading } = products;

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Breadcrumbs>
          <Typography variant="body1">Inicio</Typography>
          <Typography variant="h5" color="secondary">
            Resumen
          </Typography>
        </Breadcrumbs>
      </Paper>

      {loading ? (
        <LoadingScreen />
      ) : (
        <Paper sx={{ mt: 3 }}>
          {data?.length > 0 ? (
            <>
              <Grid container spacing={2} p={2} pt={0}>
                {data.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Product item={item} />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <NoItems />
          )}
        </Paper>
      )}
    </>
  );
};

export default Summary;
