import {
  Button,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useContext, useEffect, useState } from 'react';
import { PayContext } from '..';
import { mainApi } from '../../../../../api/mainApi';

import payDeposito from '../../../../../assets/images/pay-deposito.png';
import payTransfer from '../../../../../assets/images/pay-transfer.png';

const useStyles = makeStyles((theme) => ({
  comprobanteInput: {
    background: '#fff',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    color: theme.palette.secondary.main,
    fontWeight: 700,
    width: '100%',
    padding: '1em !important',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      background: theme.palette.secondary.main,
      cursor: 'pointer',
      color: '#fff',
    },
  },
}));

const DepositoTransferencia = () => {
  const classes = useStyles();

  const [bancos, setBancos] = useState<{ loading: boolean; data: any[] }>({
    loading: true,
    data: [],
  });

  const {
    formaPago,
    setFormaPago,
    fotoTransferDeposit,
    setFotoTransferDeposit,
    dataPagoBanco,
    setDataPagoBanco,
  } = useContext<any>(PayContext);

  useEffect(() => {
    mainApi.get('ListaCuentaBancarias').then((res) => {
      setBancos((prev) => ({
        ...prev,
        loading: false,
        data: res.data,
      }));
      setDataPagoBanco((prev: any) => ({
        ...prev,
        banco_id: res.data[0].cuenBanc_id,
      }));
    });
  }, [setBancos, setDataPagoBanco]);

  const handleUpload = (e: any) => {
    setFotoTransferDeposit(e.target.files[0]);
  };

  const handleChange = (e: any) => {
    setDataPagoBanco((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleBack = () => {
    setDataPagoBanco({
      banco_id: '',
      num_comprobante: '',
    });
    setFotoTransferDeposit(null);
    setFormaPago(0);
  };

  const { loading, data } = bancos;

  return (
    <>
      {loading ? (
        <Grid item container justifyContent="center" xs={12} mt={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} mt={1}>
            <Button
              variant="text"
              color="secondary"
              startIcon={<ArrowBack sx={{ fontSize: '2em' }} />}
              sx={{ fontWeight: 600, fontSize: '1.2em' }}
              onClick={handleBack}
            >
              &nbsp;&nbsp;Elegir otra forma de pago
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12} mt={3} spacing={1} mb={8}>
            <Grid item xs={10} md={10} textAlign="center">
              {/* eslint-disable-next-line eqeqeq */}
              {formaPago == 1 ? (
                <Grid container alignItems="center" justifyContent="center" gap="1em">
                  <Typography variant="h3" color="secondary">
                    Depósito
                  </Typography>
                  <img src={payDeposito} alt="" />
                </Grid>
              ) : (
                <Grid container alignItems="center" justifyContent="center" gap="1em">
                  <Typography variant="h3" color="secondary">
                    Transferencia
                  </Typography>
                  <img src={payTransfer} alt="" />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={8} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="h4" textAlign="right">
                  PARAMETROS DEL DOCUMENTO A CARGAR:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ul>
                  <li>Archivos admitidos JPG o PDF (máx 2Mb).</li>
                  <li>Tomar Foto con buena iuminación.</li>
                  <li>Toma de documento completo y legible.</li>
                </ul>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={7} mt={3}>
              <Grid item container justifyContent="center" xs={9} maxHeight={'200px'}>
                {!fotoTransferDeposit ? (
                  <>
                    <InputLabel htmlFor="fotoComprobante" className={classes.comprobanteInput}>
                      CARGAR COMPROBANTE
                    </InputLabel>
                    <Input
                      id="fotoComprobante"
                      name="fotoComprobante"
                      type="file"
                      onChange={handleUpload}
                      sx={{ display: 'none' }}
                    />
                  </>
                ) : (
                  <img
                    src={URL.createObjectURL(fotoTransferDeposit)}
                    alt=""
                    width="auto"
                    height="200px"
                  />
                )}
              </Grid>
              <Grid item container justifyContent="center" xs={9} mt={3}>
                <Grid item xs={12}>
                  <InputLabel>Seleccione el banco</InputLabel>
                  <TextField
                    name="banco_id"
                    value={dataPagoBanco.banco_id}
                    select
                    fullWidth
                    onChange={handleChange}
                  >
                    {data.map((option) => (
                      <MenuItem value={option.cuenBanc_id}>{option.cuenBanc_nombre}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {formaPago === 1 && (
                  <Grid item xs={12} mt={2}>
                    <InputLabel>Número de comprobante</InputLabel>
                    <TextField
                      name="num_comprobante"
                      value={dataPagoBanco.num_comprobante}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DepositoTransferencia;
