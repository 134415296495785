import { mainApi } from '../api/mainApi';
import { showAlert, showConfirmationAlert, showLoadingAlert } from './alerts';

export const getAllPayments = async () => {
  const resp = await mainApi.get('listaFacturas');
  //console.log(resp);
  if (resp?.data?.status === '200') {
    return resp?.data?.lista;
  } else {
    return [];
  }
};

export const refundPayment = async (id_pago: string, total: string) => {
  showConfirmationAlert(
    '¿Desea realizar el reembolso?',
    'Recuerde que solo puede intentar el reembolso un máximo de 5 veces'
  ).then(async (result) => {
    if (result.isConfirmed) {
      showLoadingAlert('Reembolso en proceso...');
      const resp = await mainApi.post('ReembolsoFacturas', {
        id_pago,
        total,
      });
      //console.log(resp);
      if (resp?.data?.status === '200') {
        if (resp?.data?.msg === 'Reembolso Fallido') {
          showAlert('Reembolso Fallido', 'El reembolso no pudo ser realizado', 'error');
        } else {
          showAlert('Reembolso Exitoso', 'El reembolso se realizó con éxito', 'success');
        }
      } else {
        showAlert('Reembolso Fallido', 'El reembolso no pudo ser realizado', 'error');
        return false;
      }
    } else {
      return false;
    }
  });
};

export const getClientPayments = async (clie_id_contifico: string) => {
  const resp = await mainApi.post('ListaFactClientContificoC', {
    cliente_id: clie_id_contifico,
  });
  if (resp?.data?.status === '200') {
    return resp?.data?.lista;
  } else {
    return [];
  }
};

export const getClientDebts = async (clie_id_contifico: string) => {
  const resp = await mainApi.post('ListaFactClientContificoPc', {
    cliente_id: clie_id_contifico,
  });
  if (resp?.data?.status === '200') {
    return resp?.data?.lista;
  } else {
    return [];
  }
};

export const getClientPaymentsInProgress = async (clie_id: number) => {
  const resp = await mainApi.post('listaPagoCliente', {
    clie_id,
  });
  if (resp?.data) {
    return resp?.data;
  } else {
    return [];
  }
};

export const getPaymentStatus = async (id_pago: string) => {
  showLoadingAlert('Cargando...');
  const resp = await mainApi.post('estadoPagoWebFacturas', {
    id_pago,
  });
  if (resp?.data?.status === '200') {
    showAlert(
      'Estado del pago',
      resp?.data?.estadoPago?.transaction?.current_status,
      resp?.data?.estadoPago?.transaction?.status === 'success' ? 'success' : 'error'
    );
    return resp?.data?.estado;
  } else {
    showAlert('Error', 'No se pudo obtener el estado del pago', 'error');
    return false;
  }
};
