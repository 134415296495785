// import { useDispatch } from 'react-redux';

export const setMenuOpen = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setMenuOpen',
    payload: id,
  });
};

export const setThemeMode = (navType: any) => async (dispatch: any) => {
  dispatch({
    type: 'setThemeMode',
    payload: navType,
  });
};

export const setPresetColor = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setPresetColor',
    payload: id,
  });
};
export const setLocale = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setLocale',
    payload: id,
  });
};
export const setSidebarOpened = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setSidebarOpened',
    payload: id,
  });
};
export const setRFontFamily = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setFontFamily',
    payload: id,
  });
};
export const setRBorderRadius = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setBorderRadius',
    payload: id,
  });
};

export const setROutlinedFilled = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'setOutlinedFilled',
    payload: id,
  });
};

export const resetCustomization = (id: any) => async (dispatch: any) => {
  dispatch({
    type: 'resetCustomization',
    payload: id,
  });
};
