import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import DepositoTransferencia from './DepositoTransferencia';
import Tarjeta from './Tarjeta';

import payCard from '../../../../../assets/images/pay-card.png';
import payDeposito from '../../../../../assets/images/pay-deposito.png';
import payTransfer from '../../../../../assets/images/pay-transfer.png';
import { PayContext } from '..';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  formaPago: {
    height: '100px',
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1em',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary[200],
    },
  },
}));

const Pago = ({
  tipoPago,
  setTipoPago,
  formaPago,
  setFormaPago,
  fotoTransferDeposit,
  setFotoTransferDeposit,
}: any) => {
  const classes = useStyles();
  const { isHabilitadoDepositTransfer } = useContext<any>(PayContext);

  const handleClick = (id: number) => {
    setFormaPago(id);
  };

  return (
    <Grid item container xs={12} justifyContent="center">
      {isHabilitadoDepositTransfer ? (
        <>
          {formaPago === 0 && (
            <>
              <Grid item container justifyContent="center" xs={12} mt={3}>
                <Typography variant="h2">Seleccione la forma de pago</Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={12} mt={3} spacing={1}>
                <Grid item xs={12} md={3} minWidth="300px">
                  <Box className={classes.formaPago} onClick={() => handleClick(3)}>
                    <Typography>Crédito o débito</Typography>
                    <img src={payCard} alt="" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} minWidth="300px" sx={{ height: '108px' }}>
                  <Box className={classes.formaPago} onClick={() => handleClick(1)}>
                    <Typography>Depósito</Typography>
                    <img src={payDeposito} alt="" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} minWidth="300px">
                  <Box className={classes.formaPago} onClick={() => handleClick(2)}>
                    <Typography>Transferencia</Typography>
                    <img src={payTransfer} alt="" />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container justifyContent="center" xs={12} mt={3} spacing={1}>
            {formaPago === 1 && <DepositoTransferencia />}
            {formaPago === 2 && <DepositoTransferencia />}
            {formaPago === 3 && <Tarjeta />}
          </Grid>
        </>
      ) : (
        <Tarjeta />
      )}
    </Grid>
  );
};

export default Pago;
