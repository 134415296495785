import { mainApi } from '../api/mainApi';
import { CartItem, ProductItem } from '../types';

export const getProduct = async (item: CartItem) => {
  const resp = await mainApi.post(`InfoVisualizaCarrito`, item);
  if (resp?.data?.plan) {
    return resp.data;
  } else {
    return {
      error: resp?.data?.msg,
    };
  }
};

export const getMyProducts = async (clie_id: number) => {
  const resp = await mainApi.post('productosCliente', { clie_id });
  if (resp?.data?.status === '200') {
    return resp?.data?.productos.map((item: ProductItem) => transformItemProduct(item));
  } else {
    return [];
  }
};

export const saveProductOnDB = async (item: CartItem) => {
  const clie_id = localStorage.getItem('user_id');
  const { data } = await mainApi.post(`AgregaCarrito`, { ...transformItem(item), clie_id });
  if (data) {
    return data;
  }
};

export const updateProductOnDB = async (item: CartItem) => {
  const { data } = await mainApi.post(`EditaCarritoCliente`, transformItem(item));
  if (data) {
    return data;
  } else {
    return {
      error: 'Error al guardar el producto',
    };
  }
};

export const deleteProductOnDB = async (item: CartItem) => {
  const { data } = await mainApi.post(`EliminaCarritoCliente`, { carri_id: item.carri_id });
  if (data) {
    return data;
  } else {
    return {
      error: 'Error al eliminar el producto',
    };
  }
};

export const transformItem = (item: any): CartItem => {
  const carri_id = item.carri_id ? parseInt(item.carri_id) : 0;
  const prod_id = parseInt(item.prod_id);
  const plan_id = parseInt(item.plan_id);
  const opci_pago_id = parseInt(item.opci_pago_id);
  const carri_cantidad = parseInt(item.carri_cantidad);

  return {
    carri_id,
    prod_id,
    plan_id,
    opci_pago_id,
    carri_cantidad,
  };
};

export const transformItemProduct = (item: any): ProductItem => {
  const opci_pago_id = parseInt(item.opci_pago_id);
  const prod_id = parseInt(item.prod_id);
  const prod_plan_id = parseInt(item.prod_plan_id);
  return {
    ...item,
    opci_pago_id,
    prod_id,
    prod_plan_id,
  };
};
