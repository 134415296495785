// material ui
import { Box, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// project imports
import MinimalLayout from '../layout/MinimalLayout';

// constants
import { gridSpacing } from '../constants/uiConstants';

// style constant
const useStyles = makeStyles({
  boxContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    padding: '32px',
  },
});

const ProfilesScreen = () => {
  const classes = useStyles();

  return (
    <MinimalLayout>
      <Box className={classes.boxContainer}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={gridSpacing}
        >
          <CircularProgress color="primary" size={50} />
        </Grid>
      </Box>
    </MinimalLayout>
  );
};

export default ProfilesScreen;
