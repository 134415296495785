// helpers

const initialState: { open: boolean } = {
  open: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'setOpenTerms':
      return {
        ...state,
        open: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
