import { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import MoneyOff from '@material-ui/icons/MoneyOff';
import { getPaymentStatus, refundPayment } from '../../../../helpers/payments';
import time from '../../../../helpers/time';
import { IconEye } from '@tabler/icons';

const useStyles = makeStyles((theme) => ({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  row: {
    '& td, & th': { border: 0, py: 0.5 },
    '& td:first-of-type': { borderRadius: '24px 0 0 24px' },
    '& td:last-child': { borderRadius: '0 24px 24px 0' },
    background: '#e1e1e1',
    borderRadius: '12px',
  },
}));

const PaymentsTable = ({ data }: any) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const initialPerPage = 10;

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  const count = data.length;
  const init = page * perPage;
  const end = page === Math.ceil(count / perPage) - 1 ? count : init + perPage;

  const ref = useRef(null);

  const handleRefund = (id: string, total: string) => {
    refundPayment(id, total).then((res) => console.log(res));
  };

  const handleStatus = (id: string) => {
    getPaymentStatus(id).then((res) => console.log(res));
  };

  return (
    <>
      <TableContainer>
        <Table
          ref={ref}
          sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 12px' }}
        >
          <TableHead>
            <TableRow sx={{ '& th ': { border: 0 } }}>
              <TableCell>ID de transaccion</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Cedula/RUC</TableCell>
              <TableCell>Nombre comercial</TableCell>
              <TableCell>Correo electrónico</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(init, end).map((item: any, index: number) => (
              <TableRow key={index} className={classes.row}>
                <TableCell>{item.cabe_Fact_numero}</TableCell>
                <TableCell>{time(item.cabe_Fact_reg).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                <TableCell>{item.cabe_Fact_idenTitular}</TableCell>
                <TableCell>{item.cabe_Fact_nombTitular}</TableCell>
                <TableCell>{item.cabe_Fact_correoTitular}</TableCell>
                <TableCell>{item.cabe_Fact_teleTitular || '(No hay información)'}</TableCell>
                <TableCell>{item.cabe_Fact_subTotal}</TableCell>
                <TableCell>{item.cabe_Fact_iva}</TableCell>
                <TableCell>{item.cabe_Fact_total}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleRefund(item.id_token, item.cabe_Fact_total)}
                    disabled={item.esta_id === '5'}
                    title="Reembolsar"
                  >
                    <MoneyOff />
                  </IconButton>
                  <IconButton title="Ver estado" onClick={() => handleStatus(item.id_token)}>
                    <IconEye />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[initialPerPage - 5, initialPerPage, initialPerPage + 5]}
        component="div"
        count={count}
        rowsPerPage={perPage}
        page={page}
        onRowsPerPageChange={(e) => {
          setPerPage(Number(e.target.value));
        }}
        onPageChange={(_, newPage) => setPage(newPage)}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${count}`}
        nextIconButtonProps={{
          disabled: page === Math.ceil(count / perPage) - 1 || count === 0,
        }}
        backIconButtonProps={{ disabled: page === 0 || count === 0 }}
      />
    </>
  );
};

export default PaymentsTable;
