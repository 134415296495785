import PaymentsTable from './tables/PaymentsInProgressTable';
import { Button, Grid, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getClientPaymentsInProgress } from '../../../../helpers/payments';
import { User } from '../../../auth/interfaces/login.interfaces';
import { useAppSelector } from '../../../../hooks/useRedux';
import LoadingScreen from '../../../shared/screens/LoadingScreen';
import { IconSearch } from '@tabler/icons';
import NoItems from './NoItems';
import time from '../../../../helpers/time';

const PaymentsInProgress = () => {
  const user: User = useAppSelector((state) => state.auth.user);

  const [payments, setPayments] = useState<{ loading: boolean; data: any[] }>({
    loading: true,
    data: [],
  });
  const [listaFacturas, setListaFacturas] = useState<any[]>([]);

  // const [modal, setModal] = useState({
  //   isOpen: false,
  //   id: '',
  // });

  // const handleOpenModal = (id: string) => {
  //   setModal({
  //     isOpen: true,
  //     id,
  //   });
  // };

  // const handleCloseModal = () => {
  //   setModal({
  //     isOpen: false,
  //     id: '',
  //   });
  // };

  useEffect(() => {
    getClientPaymentsInProgress(user.clie_id).then((payments) => {
      setListaFacturas(payments);
      setPayments({ loading: false, data: payments });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filtros, setFiltros] = useState({
    value: '',
    fecha_desde: '',
    fecha_hasta: '',
  });

  const handleChange = (e: any) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };
  const { loading, data } = payments;

  const filter = () => {
    const { value, fecha_desde, fecha_hasta } = filtros;

    const filterData = data.filter((factura) => {
      let bol = false;
      if (value || fecha_desde || fecha_hasta) {
        if (
          factura.CabePago_num_comprobante?.toLowerCase().includes(value.toLowerCase()) ||
          factura.CabePago_idenTitular?.toLowerCase().includes(value.toLowerCase()) ||
          factura.CabePago_correoTitular?.toLowerCase().includes(value.toLowerCase()) ||
          factura.CabePago_nombTitular?.toLowerCase().includes(value.toLowerCase())
        ) {
          bol = true;
          const fecha = time(factura.cabePago_fecha_reg, 'DD/MM/YYYY', true);

          if (fecha_desde && fecha_hasta) {
            if (
              (fecha.isSame(time(fecha_desde)) || fecha.isAfter(time(fecha_desde))) &&
              (fecha.isSame(time(fecha_hasta)) || fecha.isBefore(time(fecha_hasta)))
            ) {
              bol = true;
            } else {
              bol = false;
            }
          } else if (fecha_desde) {
            if (fecha.isSame(time(fecha_desde)) || fecha.isAfter(time(fecha_desde))) {
              bol = true;
            } else {
              bol = false;
            }
          } else if (fecha_hasta) {
            if (fecha.isSame(time(fecha_hasta)) || fecha.isBefore(time(fecha_hasta))) {
              bol = true;
            } else {
              bol = false;
            }
          }
          return bol;
        } else {
          return bol;
        }
      } else {
        return true;
      }
    });
    setListaFacturas(filterData);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {data.length > 0 ? (
            <Grid container p={4}>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={3}>
                  <InputLabel>Filtro</InputLabel>
                  <OutlinedInput
                    startAdornment={<IconSearch />}
                    name="value"
                    placeholder="Buscar"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel>Fecha desde</InputLabel>
                  <TextField type="date" name="fecha_desde" fullWidth onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel>Fecha hasta</InputLabel>
                  <TextField type="date" name="fecha_hasta" fullWidth onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputLabel sx={{ color: 'white' }}>s</InputLabel>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ height: '51.13px' }}
                    fullWidth
                    onClick={filter}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <PaymentsTable data={listaFacturas} />
              </Grid>
            </Grid>
          ) : (
            <NoItems />
          )}
          {/* <Dialog open={modal.isOpen} onClose={handleCloseModal}></Dialog> */}
        </>
      )}
    </>
  );
};

export default PaymentsInProgress;
