import React from 'react';

// redux hooks
import { useAppSelector } from '../../../../../../hooks/useRedux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../../../../components/cards/MainCard';
import Transitions from '../../../../components/extended/Transitions';

// assets
import { IconLogout, IconSettings, IconShoppingCart, IconUser } from '@tabler/icons';

// redux actions
import { logouts } from '../../../../../auth/actions/authActions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { setMenuOpen } from '../../../../actions/customizationActions';
// import { useHistory } from 'react-router';

// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  headerAvatar: {
    cursor: 'pointer',
    ...theme.typography.mediumAvatar,
    margin: '8px 0 8px 8px !important',
    background: 'none',
  },
  profileChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main + '!important',
      color: theme.palette.primary.light,
      '& svg': {
        stroke: theme.palette.primary.light,
      },
    },
  },
  cartChip: {
    marginRight: '16px',
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main + '!important',
      color: theme.palette.primary.light,
      '& svg': {
        stroke: theme.palette.primary.light,
      },
    },
    '& img': {
      background: 'none !important',
    },
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px',
  },
  listItem: {
    marginTop: '5px',
  },
  cardContent: {
    padding: '16px !important',
  },
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginBottom: '16px',
    marginTop: '16px',
  },
  searchControl: {
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px',
    marginTop: '16px',
  },
  startAdornment: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
  },
  flex: {
    display: 'flex',
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400,
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'hidden',
  },
  badgeWarning: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
    color: '#fff',
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  // const dispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { customization, auth, profile } = useAppSelector((state) => state);
  const cart = useAppSelector((state) => state.cart.items);
  const location = useLocation();
  const history = useHistory();
  // const { currentProfile } = access;

  const { user } = auth;

  const theme = useTheme();
  const classes = useStyles();

  const [selectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<any>(null);

  const nombreUsuario = user.clie_nomb.split(' ')[0] + ' ' + user.clie_apell.split(' ')[0];

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const handleLogout = async () => {
    try {
      localStorage.removeItem('lastPath');
      dispatch(logouts());
      // history.replace('/login');
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.cartChip}
        icon={
          <Avatar
            src={`https://ui-avatars.com/api/?name=${cart.length}&background=6C3CA6&color=fff`}
            className={classes.headerAvatar}
            color="inherit"
          />
        }
        label={<IconShoppingCart stroke={1.5} size="1.5rem" color={theme.palette.secondary.main} />}
        variant="outlined"
        onClick={() => {
          dispatch(setMenuOpen(''));
          history.push('/carrito');
        }}
        color="primary"
      />
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        icon={
          <Avatar
            src={`https://ui-avatars.com/api/?name=${nombreUsuario}&background=0C9AE8&color=fff`}
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={1} style={{ marginBottom: 12 }}>
                      <Grid item>
                        <Typography component="span" variant="h4">
                          Buen día,{' '}
                        </Typography>
                        <Typography component="span" variant="h4" className={classes.name}>
                          {user.clie_nomb} {user.clie_apell}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {profile.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <PerfectScrollbar
                      className={classes.ScrollHeight}
                      options={{ suppressScrollX: true }}
                    >
                      <List component="nav" className={classes.navContainer}>
                        <ListItem
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + 'px',
                          }}
                          button
                          selected={location.pathname === '/perfil/editar'}
                          onClick={() => {
                            dispatch(setMenuOpen(''));
                            history.push('/perfil/editar');
                          }}
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Perfil</Typography>} />
                        </ListItem>
                        <ListItem
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + 'px',
                          }}
                          button
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={<Typography variant="body2">Cerrar sesión</Typography>}
                          />
                        </ListItem>
                      </List>
                    </PerfectScrollbar>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default ProfileSection;
