import { deleteProductOnDB } from '../../../helpers/products';
import { CartItem } from '../../../types';

export const setCartLoading = () => async (dispatch: any) => {
  dispatch({
    type: 'cart/load',
  });
};

export const setCartItems = (items: CartItem[]) => async (dispatch: any) => {
  localStorage.setItem('cart', JSON.stringify(items));
  dispatch({
    type: 'cart/set-items',
    payload: items,
  });
};

export const addToCart = (product: CartItem) => async (dispatch: any) => {
  const cart = JSON.parse(localStorage.getItem('cart') || '[]');

  localStorage.setItem('cart', JSON.stringify([...cart, product]));

  dispatch({
    type: 'cart/add',
    payload: product,
  });
};

export const updateItem = (product: CartItem) => async (dispatch: any) => {
  const cart = JSON.parse(localStorage.getItem('cart') || '[]');

  const cartUpdated = cart.map((item: CartItem) =>
    item.prod_id === product.prod_id ? product : item
  );

  localStorage.setItem('cart', JSON.stringify(cartUpdated));

  dispatch({
    type: 'cart/update',
    payload: product,
  });
};

export const deleteItem = (product: CartItem) => async (dispatch: any) => {
  const cart = JSON.parse(localStorage.getItem('cart') || '[]');

  const cartUpdated = cart.filter((item: CartItem) => item.prod_id !== product.prod_id);

  if (localStorage.getItem('x-token')) {
    deleteProductOnDB(product);
  }

  localStorage.setItem('cart', JSON.stringify(cartUpdated));

  dispatch({
    type: 'cart/remove',
    payload: product.prod_id,
  });
};

export const setItemPrice = (item: CartItem, price: number) => async (dispatch: any) => {
  dispatch({ type: 'cart/set-price', payload: { item, price } });
};

export const clearCart = () => async (dispatch: any) => {
  localStorage.removeItem('cart');
  dispatch({
    type: 'cart/clear',
  });
};
