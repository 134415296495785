import { mainApi } from '../api/mainApi';

export const validarCedula = (cedula: string) => {
  if (cedula) {
    let total = 0;
    let longitud = cedula.length;
    let longcheck = longitud - 1;
    if (cedula !== '' && longitud === 10) {
      for (let i = 0; i < longcheck; i++) {
        if (i % 2 === 0) {
          let aux = parseInt(cedula.charAt(i)) * 2;
          if (aux > 9) aux -= 9;
          total += aux;
        } else {
          total += parseInt(cedula.charAt(i)); // parseInt o concatenará en lugar de sumar
        }
      }

      total = total % 10 ? 10 - (total % 10) : 0;

      return parseInt(cedula.charAt(longitud - 1)) === total;
    }
  }
  return false;
};

export const cedulaNoRegistrada = async (cedula: string) => {
  await mainApi
    .post(`validaCedulaInput`, { cedula })
    .then((resp) => {
      if (resp?.data?.status === '200') {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return true;
    });
};
