import { Checkbox, Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { mainApi } from '../../../../../api/mainApi';
import LoadingScreen from '../../../../shared/screens/LoadingScreen';

const DatosFacturacion = ({
  datosFact,
  handleDataFactChange,
  setDatosFact,
  tipoPago,
  setTipoPago,
}: any) => {
  const [dataSelect, setDataSelect] = useState({
    loading: true,
    listaTipoFact: [],
  });

  useEffect(() => {
    mainApi.get('listTpClienteDtFactura').then((res) => {
      if (res?.data) {
        setDataSelect((prev) => ({
          ...prev,
          loading: false,
          listaTipoFact: res.data,
        }));
      }
    });
  }, []);

  const { loading, listaTipoFact } = dataSelect;

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="clie_fact_razon_social">Razón social</InputLabel>
            <TextField
              id="clie_fact_razon_social"
              value={datosFact.clie_fact_razon_social}
              onChange={handleDataFactChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="clie_fact_ruc">RUC</InputLabel>
            <TextField
              id="clie_fact_ruc"
              value={datosFact.clie_fact_ruc}
              onChange={handleDataFactChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="clie_fact_nomb_comer">Nombre comercial</InputLabel>
            <TextField
              id="clie_fact_nomb_comer"
              value={datosFact.clie_fact_nomb_comer}
              onChange={handleDataFactChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel htmlFor="clie_fact_dire">Dirección</InputLabel>
            <TextField
              id="clie_fact_dire"
              fullWidth
              value={datosFact.clie_fact_dire}
              onChange={handleDataFactChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="clie_fact_tele">Teléfono</InputLabel>
            <TextField
              id="clie_fact_tele"
              value={datosFact.clie_fact_tele}
              onChange={handleDataFactChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="clie_fact_correo">Correo</InputLabel>
            <TextField
              id="clie_fact_correo"
              value={datosFact.clie_fact_correo}
              onChange={handleDataFactChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel htmlFor="clie_fact_tipo_id">Figura legal</InputLabel>
            <TextField
              select
              id="clie_fact_tipo_id"
              value={`${datosFact.clie_fact_tipo_id}`}
              onChange={({ target }) =>
                setDatosFact({
                  ...datosFact,
                  clie_fact_tipo_id: parseInt(target.value),
                })
              }
              fullWidth
            >
              {listaTipoFact.map((item: any) => (
                <MenuItem key={item.clie_fact_tipo_id} value={`${item.clie_fact_tipo_id}`}>
                  {item.clie_fact_tipo_deta}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item container xs={12} alignItems="center">
            <Grid item>
              <InputLabel htmlFor="clie_fact_contri_espe">Contribuyente especial</InputLabel>
            </Grid>
            <Grid item>
              <Checkbox
                value={datosFact.clie_fact_contri_espe}
                checked={datosFact.clie_fact_contri_espe === 'N'}
                onChange={({ target }) =>
                  setDatosFact((prev: any) => ({
                    ...prev,
                    clie_fact_contri_espe: target.checked ? 'N' : '',
                  }))
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DatosFacturacion;
