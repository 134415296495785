// project imports
import NavGroup from './NavGroup';
// import { globalMenuItems } from './../../../../../roles/sistemas/menu-items';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
  return <NavGroup />;
};

export default MenuList;
