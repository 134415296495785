import { Box, Grid, makeStyles, Typography, Chip } from '@material-ui/core';
import { urlWeb } from '../../../../api/mainApi';
import { ProductItem } from '../../../../types/index';

interface IProps {
  item: ProductItem;
}

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: '12px',
    height: '100%',
    overflow: 'hidden',
    background: '#e2e2e2',
    position: 'relative',
  },
  chip: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
}));

const Item = ({ item }: IProps) => {
  const classes = useStyles();

  return (
    // <Grid container className={classes.box}>
    //   <Grid item xs={12} height="120px" overflow="hidden">
    //     <img src="https://i.ytimg.com/vi/UV5D9633CW0/maxresdefault.jpg" width="100%" alt="" />
    //   </Grid>
    //   <Grid item container p={2} xs={12}>
    //     <Grid item xs={12}>
    //       <Typography variant="h4">{item.prod_plan_nombre}</Typography>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Typography variant="body1">Tipo de pago: {item.opci_pago_deta}</Typography>
    //     </Grid>
    //   </Grid>
    // </Grid>
    <Box className={classes.box}>
      <Grid item xs={12} height="140px" overflow="hidden">
        <img
          src={`${urlWeb}/img/baner_${item.prod_id}.png`}
          width="100%"
          alt=""
          style={{ minHeight: '120px' }}
        />
        <Chip label={item.opci_pago_deta} className={classes.chip} />
      </Grid>
      <Grid item container p={2} xs={12} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary" sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
            {item.nomb_alias_prod}
          </Typography>
          <Typography variant="body1">{item.prod_plan_nombre}</Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="body1">Tipo de pago: {item.opci_pago_deta}</Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Item;
