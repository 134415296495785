// reducers
import authReducer from '../modules/auth/reducers/authReducer';
import customizationReducer from '../modules/shared/reducers/customizationReducer';
import profileReducer from '../modules/shared/reducers/profileReducer';
import cartReducer from '../modules/shared/reducers/cartReducer';
import termsReducer from '../modules/shared/reducers/termsReducer';

import { combineReducers } from 'redux';
import { AuthState, CustomizationState } from '../types';

export interface IReducer {
  auth: AuthState;
  customization: CustomizationState;
}

const reducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  customization: customizationReducer,
  profile: profileReducer,
  terms: termsReducer,
  // revisorAsign: revisorAsignReducer,
});

export default reducer;
