import { CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { urlWeb } from '../../../../../api/mainApi';
import { getProduct } from '../../../../../helpers/products';
import time from '../../../../../helpers/time';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { CartItem, Product } from '../../../../../types';
import { setItemPrice } from '../../../../shared/actions/cartActions';
import CancelIcon from '@material-ui/icons/Cancel';

interface CartGridItemProps {
  cartItem: CartItem;
  handleItemDelete: any;
}

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
    borderRadius: '8px',
    padding: '16px',
  },
}));

const CartGridItem = ({ cartItem, handleItemDelete }: CartGridItemProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [item, setItem] = useState<{ loading: boolean; data: Product | null }>({
    loading: true,
    data: null,
  });

  useEffect(() => {
    getProduct(cartItem).then((product) => {
      setItem((prev) => ({
        ...prev,
        loading: false,
        data: product,
      }));
    });
  }, [cartItem]);

  useEffect(() => {
    dispatch(
      setItemPrice(cartItem, parseFloat(item.data?.plan?.carri_total.replaceAll(',', '') || '0'))
    );
  }, [item?.data, cartItem, dispatch]);

  const { loading, data } = item;

  return (
    <Grid item container className={classes.card}>
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item>
              <img
                src={`${urlWeb}/img/icono_${cartItem.prod_id}.png`}
                alt=""
                width="50px"
                style={{
                  borderRadius: '12px',
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h4" color="secondary">
                {data?.plan?.prod_nombre}
              </Typography>
              <Typography>{data?.plan?.prod_plan_nombre}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Grid item xs={12} mt={0.5}>
                <Typography variant="h5" color="secondary">
                  Cantidad: {cartItem.carri_cantidad}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={0.5}>
                <Typography variant="h5" color="secondary">
                  Vencimiento: {time().add(1, 'year').format('YYYY-MM-DD')}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={0.5}>
                <Typography variant="h4" color="secondary">
                  Total: ${data?.plan?.carri_total}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <IconButton onClick={() => handleItemDelete(cartItem)}>
                  <CancelIcon sx={{ fill: '#9f9f9f' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CartGridItem;
