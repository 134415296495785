import { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import IntegrationInstructions from '@material-ui/icons/IntegrationInstructions';

const PaymentsTable = ({ data }: any) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const initialPerPage = 10;

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  const count = data.length;
  const init = page * perPage;
  const end = page === Math.ceil(count / perPage) - 1 ? count : init + perPage;

  const ref = useRef(null);

  return (
    <>
      <TableContainer>
        <Table
          ref={ref}
          sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 12px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>ID de transaccion</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Cedula/RUC</TableCell>
              <TableCell>Nombre comercial</TableCell>
              <TableCell>Correo electrónico</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(init, end).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{item.documento}</TableCell>
                <TableCell>{item.fecha_creacion}</TableCell>
                <TableCell>{item.persona.cedula}</TableCell>
                <TableCell>{item.persona.nombre_comercial}</TableCell>
                <TableCell>{item.persona.email}</TableCell>
                <TableCell>{item.subtotal_12}</TableCell>
                <TableCell>{item.iva}</TableCell>
                <TableCell>{item.total}</TableCell>
                <TableCell>
                  <IconButton
                    disabled={!item.url_ride}
                    title="Descargar PDF"
                    component="a"
                    href={item.url_ride}
                    target="_blank"
                  >
                    <PictureAsPdf />
                  </IconButton>
                  <IconButton
                    disabled={!item.url_xml}
                    title="Descargar XML"
                    component="a"
                    href={item.url_xml}
                    target="_blank"
                  >
                    <IntegrationInstructions />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[initialPerPage - 5, initialPerPage, initialPerPage + 5]}
        component="div"
        count={count}
        rowsPerPage={perPage}
        page={page}
        onRowsPerPageChange={(e) => {
          setPerPage(Number(e.target.value));
        }}
        onPageChange={(_, newPage) => setPage(newPage)}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${count}`}
        nextIconButtonProps={{
          disabled: page === Math.ceil(count / perPage) - 1 || count === 0,
        }}
        backIconButtonProps={{ disabled: page === 0 || count === 0 }}
      />
    </>
  );
};

export default PaymentsTable;
