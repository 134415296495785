import { Breadcrumbs, Grid, Link, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import MyDebts from './MyDebts';
import MyPayments from './MyPayments';
import PaymentsInProgress from './PaymentsInProgress';

const getTabContent = (tabActive: number) => {
  switch (tabActive) {
    case 0:
      return <MyDebts />;
    case 1:
      return <MyPayments />;
    case 2:
      return <PaymentsInProgress />;
    default:
      return;
  }
};

const Transactions = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const path = location.search;
    if (path.includes('v=pagos')) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      history.replace(`${location.pathname}?v=deudas`);
    } else {
      history.replace(`${location.pathname}?v=pagos`);
    }
  }, [history, location.pathname, activeTab]);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" color="secondary" />}>
          <Link component={RouterLink} to="/" underline="hover" color="black">
            Inicio
          </Link>
          <Typography color="secondary" variant="h5">
            Transacciones
          </Typography>
        </Breadcrumbs>
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          onChange={(_, value) => {
            setActiveTab(value);
          }}
        >
          <Tab value={0} label="Transacciones pendientes" />
          <Tab value={2} label="Transacciones en proceso" />
          <Tab value={1} label="Transacciones realizadas" />
        </Tabs>
        <Grid container justifyContent="center">
          {getTabContent(activeTab)}
        </Grid>
      </Paper>
    </>
  );
};

export default Transactions;
