import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CartItem } from '../../../../../types';
import CartTableItem from './CartItem';

interface CartTableProps {
  cartItems: CartItem[];
  handleItemDelete: any;
}

const CartTable = ({ cartItems, handleItemDelete }: CartTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Items/Suscripciones</TableCell>
            <TableCell align="center">Cantidad</TableCell>
            <TableCell>Fecha de vencimiento</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((cartItem, index) => (
            <CartTableItem key={index} cartItem={cartItem} handleItemDelete={handleItemDelete} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CartTable;
