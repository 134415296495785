import { FormHelperText, Grid, TextField, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { showAlert } from '../../../../../helpers/alerts';
import { changeUserPassword } from '../../../../../helpers/user';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { User } from '../../../../auth/interfaces/login.interfaces';
import InputLabel from '../../../../shared/components/forms/InputLabel';

const noSpaceRegex = RegExp('^[^ ]+$');
const lowercaseRegex = RegExp('(?=.*[a-z])');
const uppercaseRegex = RegExp('(?=.*[A-Z])');
const numericRegex = RegExp('(?=.*[0-9])');
const specialCharactersRegex = RegExp('(?=.*[.!@#$%^&*])');
const minmaxRegex = RegExp('(?=.{8,})');

const validationSchema = Yup.object().shape({
  actual_password: Yup.string(),
  new_password: Yup.string()
    .matches(noSpaceRegex, 'No se permiten espacios en blanco')
    .matches(lowercaseRegex, 'Debe tener por lo menos una minuscula')
    .matches(uppercaseRegex, 'Debe tener por lo menos una mayuscula')
    .matches(numericRegex, 'Debe tener por lo menos un número')
    .matches(specialCharactersRegex, 'Debe tener por lo menos un caracter especial')
    .matches(minmaxRegex, 'Debe tener por lo menos 8 caracteres'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Las contraseñas deben coincidir.'
  ),
});

const PasswordChange = () => {
  const user: User = useAppSelector((state) => state.auth.user);

  const { getFieldProps, errors, touched, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      actual_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      changeUserPassword(user.clie_id, values.actual_password, values.new_password).then(
        (res: any) => {
          if (res.status === 'success') {
            showAlert('¡Listo!', 'La contraseña se actualizó correctamente', 'success');
          } else if (res.status === 'error_pass') {
            setFieldError('actual_password', res.message);
          } else {
            showAlert('¡Error!', res.message, 'error');
          }
        }
      );
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
          <Grid item xs={6}>
            <InputLabel htmlFor="actual_password">Contraseña actual</InputLabel>
            <TextField
              type="password"
              error={Boolean(errors.actual_password && touched.actual_password)}
              id="actual_password"
              fullWidth
              {...getFieldProps('actual_password')}
            />
            {errors.actual_password && touched.actual_password && (
              <FormHelperText error id="usernameError">
                {errors.actual_password}
              </FormHelperText>
            )}
          </Grid>
          <Grid item md={6} sx={{ display: { xs: 'hidden' } }}></Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="new_password">Nueva contraseña</InputLabel>
            <TextField
              type="password"
              error={Boolean(errors.new_password && touched.new_password)}
              id="new_password"
              fullWidth
              {...getFieldProps('new_password')}
            />
            {errors.new_password && touched.new_password && (
              <FormHelperText error id="usernameError">
                {errors.new_password}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="confirm_password">Confirma tu contraseña</InputLabel>
            <TextField
              type="password"
              error={Boolean(errors.confirm_password && touched.confirm_password)}
              id="confirm_password"
              fullWidth
              {...getFieldProps('confirm_password')}
            />
            {errors.confirm_password && touched.confirm_password && (
              <FormHelperText error id="usernameError">
                {errors.confirm_password}
              </FormHelperText>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item>
              <Button type="submit" variant="contained" color="secondary" sx={{ px: 4 }}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PasswordChange;
