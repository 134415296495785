// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// // types
// import { Property } from 'csstype';
// import { PaletteMode } from '@material-ui/core';
import { CurrentProfileState } from '../../../types';

// helpers

const initialState: CurrentProfileState = {
  id: 0,
  name: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'setCurrentProfile':
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
