import { InputLabel as MUIInputLabel, InputLabelProps } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const InputLabel = (props: InputLabelProps) => {
  const classes = useStyles();

  return <MUIInputLabel {...props} className={classes.inputLabel} />;
};

export default InputLabel;
