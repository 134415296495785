import { mainApi } from '../api/mainApi';
import { showAlert, showLoadingAlert } from './alerts';

export const guardarPago = async (
  cliente_id: any,
  paymentezResponse: any,
  datosFact: any = {},
  otrosDatos: number = 0
) => {
  mainApi.post('/GuardarPagoPay', { json: JSON.stringify(paymentezResponse) });
  showLoadingAlert('Guardando pago...');
  if (
    paymentezResponse.transaction?.status === 'success' &&
    paymentezResponse.transaction?.current_status === 'APPROVED'
    // paymentezResponse.transaction?.carrier_code === '00'
  ) {
    const res = await mainApi.post('/FacturarCliente', {
      cliente_id,
      json: JSON.stringify(paymentezResponse),
      datosFact: JSON.stringify(datosFact),
      otrosDatos,
    });
    if (res?.data?.status === '201') {
      showAlert('¡Exito!', 'Pago registrado con éxito.', 'success');
      return { isConfirmed: true };
    }
  } else {
    showAlert('¡Error!', 'Pago no exitoso', 'error');
    return { isConfirmed: false };
  }
};

export const guardarPagoDepositTransfer = async (
  cliente_id: any,
  formaPago_id: number,
  datosFacturacion: any,
  banco_id: any,
  numComprobante: any,
  archivo: any
) => {
  const formData = new FormData();
  formData.append('cliente_id', cliente_id);
  formData.append('formaPago_id', `${formaPago_id}`);
  formData.append('datosFacturacion', JSON.stringify(datosFacturacion));
  formData.append('banco_id', banco_id);
  formData.append('numComprobante', numComprobante);
  formData.append('archivo', archivo);

  showLoadingAlert('Guardando pago...');
  const resp = await mainApi.post('/PagoCliente', formData);
  if (resp?.data?.status === '200') {
    showAlert('¡Exito!', 'Pago registrado con éxito.', 'success', true);
    return { isConfirmed: true };
  } else {
    showAlert('¡Error!', 'Pago no exitoso', 'error', true);
    return { isConfirmed: false };
  }
};

export const guardarPagoDeuda = async (cliente_id: any, paymentezResponse: any, deudas: any[]) => {
  //console.log();
  showLoadingAlert('Guardando pago...');
  //console.log(paymentezResponse);

  mainApi.post('/GuardarPagoPay', { json: JSON.stringify(paymentezResponse) });
  if (
    paymentezResponse.transaction?.status === 'success' &&
    paymentezResponse.transaction?.current_status === 'APPROVED'
    // paymentezResponse.transaction?.carrier_code === '00'
  ) {
    //arreglo de json
    //clie_id
    //id_pago de paymentez

    const json = deudas.map((item: any) => item.json);

    const resp = await mainApi.post('PagoFacturaAdeudaMigrar', {
      clie_id: cliente_id,
      json: JSON.stringify(json),
      id_pagoWeb: paymentezResponse.transaction?.id,
    });
    if (resp) {
      showAlert('¡Exito!', 'Pago registrado con éxito.', 'success');
      return true;
    }
  } else {
    showAlert('¡Error!', 'Pago no exitoso', 'error');
    return false;
  }
  return false;
};

export const getCheckout = (callback: (response: any) => any) => {
  let timer: NodeJS.Timeout | null = null;
  // @ts-ignore
  const PaymentCheckout = new window.PaymentCheckout.modal({
    client_app_code: process.env.REACT_APP_PAYMENTEZ_CLIENT_ID,
    client_app_key: process.env.REACT_APP_PAYMENTEZ_CLIENT_KEY,
    locale: 'es',
    env_mode: 'prod',
    onClose: function (this) {
      //@ts-ignore
      clearTimeout(timer);
      document.body.removeChild(this);
    },
    onOpen: function (this) {
      timer = setTimeout(() => {
        showAlert('¡Tiempo de transaccion excedido!', 'Se procederá a cerrar', 'error');
        this.close();
      }, 1800000);
    },
    onResponse: function (response: any) {
      callback(response);
    },
  });

  //console.log(PaymentCheckout);

  return PaymentCheckout;
};
