import {
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useEffect, useState } from 'react';
import { getProduct } from '../../../../../../helpers/products';
import { CartItem, Product } from '../../../../../../types';
import time from '../../../../../../helpers/time';
import { setItemPrice } from '../../../../../shared/actions/cartActions';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { urlWeb } from '../../../../../../api/mainApi';

interface CartTableItemProps {
  cartItem: CartItem;
  handleItemDelete: any;
}

const CartTableItem = ({ cartItem, handleItemDelete }: CartTableItemProps) => {
  const dispatch = useAppDispatch();

  const [item, setItem] = useState<{ loading: boolean; data: Product | null }>({
    loading: true,
    data: null,
  });

  useEffect(() => {
    getProduct(cartItem).then((product) => {
      setItem((prev) => ({
        ...prev,
        loading: false,
        data: product,
      }));
    });
  }, [cartItem]);

  useEffect(() => {
    dispatch(
      setItemPrice(cartItem, parseFloat(item.data?.plan?.carri_total.replaceAll(',', '') || '0'))
    );
  }, [item?.data, cartItem, dispatch]);

  const { loading, data } = item;

  return (
    <TableRow>
      {loading ? (
        <TableCell colSpan={3} align="center">
          <CircularProgress />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <img
                  src={`${urlWeb}/img/icono_${cartItem.prod_id}.png`}
                  alt=""
                  width="50px"
                  style={{
                    borderRadius: '12px',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" color="secondary">
                  {data?.plan?.prod_nombre}
                </Typography>
                <Typography>{data?.plan?.prod_plan_nombre}</Typography>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="center">{data?.plan?.carri_cantidad}</TableCell>
          <TableCell>{time().add(1, 'year').format('YYYY-MM-DD')}</TableCell>
          <TableCell>${data?.plan?.carri_total}</TableCell>
          <TableCell>
            <IconButton onClick={() => handleItemDelete(cartItem)}>
              <CancelIcon sx={{ fill: '#9f9f9f' }} />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default CartTableItem;
