import { useEffect, useState } from 'react';
import { getClientCart } from '../../../helpers/cart';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import NoAuthLayout from '../../shared/layout/NoAuthLayout';
import GeneralLayout from '../../shared/layout/GeneralLayout';
import { setCartItems } from '../../shared/actions/cartActions';
import { transformItem } from '../../../helpers/products';
import AuthCart from './AuthCart';
import NoAuthCart from './NoAuthCart';

const Cart = () => {
  const dispatch = useAppDispatch();

  // const { items } = useAppSelector((state) => state.cart);
  const auth = useAppSelector((state) => state.auth);

  const Layout = auth.isAuth ? GeneralLayout : NoAuthLayout;

  const [, setCart] = useState<{ loading: boolean }>({
    loading: true,
  });

  useEffect(() => {
    if (auth.isAuth) {
      getClientCart(parseInt(auth.user?.clie_id)).then((res) => {
        const cartItems = res.map((item: any) => transformItem(item));
        dispatch(setCartItems(cartItems));
        setCart({ loading: false });
      });
    } else {
      setCart((state) => ({ ...state, loading: false }));
    }
  }, [auth.isAuth, auth.user?.clie_id, dispatch]);

  //console.log(cart);
  return <Layout>{auth.isAuth ? <AuthCart /> : <NoAuthCart />}</Layout>;
};

export default Cart;
