import { FunctionComponent, useEffect } from 'react';
import { StaticContext } from 'react-router';
import { withRouter, RouteComponentProps } from 'react-router-dom';

//-----------------------|| NAVIGATION SCROLL TO TOP ||-----------------------//

const NavigationScroll: FunctionComponent<
  RouteComponentProps<any, StaticContext, unknown>
> = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return <>{children}</>;
};

export default withRouter(NavigationScroll);
