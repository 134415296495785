import { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

const PaymentsInProgressTable = ({ data }: any) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const initialPerPage = 10;

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  const count = data.length;
  const init = page * perPage;
  const end = page === Math.ceil(count / perPage) - 1 ? count : init + perPage;

  const ref = useRef(null);

  return (
    <>
      <TableContainer>
        <Table
          ref={ref}
          sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 12px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>ID de transaccion</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Cedula/RUC</TableCell>
              <TableCell>Nombre comercial</TableCell>
              <TableCell>Correo electrónico</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
              {/* <TableCell>Acción</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(init, end).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{item.CabePago_num_comprobante}</TableCell>
                <TableCell>{item.cabePago_fecha_reg}</TableCell>
                <TableCell>{item.CabePago_idenTitular}</TableCell>
                <TableCell>{item.CabePago_nombTitular}</TableCell>
                <TableCell>{item.CabePago_correoTitular}</TableCell>
                <TableCell>{item.cabePago_subTotal}</TableCell>
                <TableCell>{item.cabePago_iva}</TableCell>
                <TableCell>{item.cabePago_total}</TableCell>
                {/* <TableCell>
                  <IconButton
                    title="Ver productos"
                    onClick={() => handleOpenModal(item.cabePago_id)}
                  >
                    <PictureAsPdf />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[initialPerPage - 5, initialPerPage, initialPerPage + 5]}
        component="div"
        count={count}
        rowsPerPage={perPage}
        page={page}
        onRowsPerPageChange={(e) => {
          setPerPage(Number(e.target.value));
        }}
        onPageChange={(_, newPage) => setPage(newPage)}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${count}`}
        nextIconButtonProps={{
          disabled: page === Math.ceil(count / perPage) - 1 || count === 0,
        }}
        backIconButtonProps={{ disabled: page === 0 || count === 0 }}
      />
    </>
  );
};

export default PaymentsInProgressTable;
