// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, ButtonBase, useTheme } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.main,
    color:
      theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, alignItems: 'center' }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
          }}
          color="secondary"
        >
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" color={theme.palette.primary.main} />
          </Avatar>
        </ButtonBase>
      </div>

      <div className={classes.grow} />
      <div className={classes.grow} />

      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}></Box>
    </>
  );
};

export default Header;
