import {
  IconUsers,
  IconHome,
  IconShieldLock,
  IconBuildingStore,
  IconReceipt2,
} from '@tabler/icons';
// Icon Group
const icons = {
  IconUsers,
  IconHome,
  IconShieldLock,
  IconBuildingStore,
  IconReceipt2,
};

export const globalMenuItems = {
  id: 'global',
  type: 'group',
  children: [
    {
      id: 'resumen',
      title: 'Resumen',
      type: 'item',
      url: '/resumen',
      icon: icons['IconHome'],
      permissionKey: '1',
    },
  ],
};

export const generalMenuItems = {
  id: 'general',
  type: 'group',
  children: [
    {
      id: 'resumen',
      title: 'Resumen',
      type: 'item',
      url: '/resumen',
      icon: icons['IconHome'],
    },
    {
      id: 'pagos',
      title: 'Pagos',
      type: 'collapse',
      icon: icons['IconReceipt2'],
      children: [
        {
          id: 'transacciones',
          title: 'Transacciones',
          type: 'item',
          url: '/transacciones',
        },
        {
          id: 'carrito',
          title: 'Carrito',
          type: 'item',
          url: '/carrito',
        },
      ],
    },
    {
      id: '/perfil/editar',
      title: 'Mi Cuenta',
      type: 'item',
      url: '/perfil/editar',
      icon: icons['IconUsers'],
    },
    // {
    //   id: 'suscripciones',
    //   title: 'Suscripciones',
    //   type: 'item',
    //   url: '/suscripciones',
    //   icon: icons['IconBuildingStore'],
    // },
  ],
};

export const adminMenuItems = {
  id: 'general',
  type: 'group',
  children: [],
};
