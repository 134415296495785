import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

// project imports
import MinimalLayout from '../modules/shared/layout/MinimalLayout';
import NavMotion from '../modules/shared/layout/NavMotion';
import Loadable from '../modules/shared/components/Loadable';

// screens
const LoginScreen = Loadable(lazy(() => import('../modules/auth/screens/LoginScreen')));
const LoginWithTokenScreen = Loadable(
  lazy(() => import('../modules/auth/screens/LoginWithTokenScreen'))
);
const RegisterScreen = Loadable(lazy(() => import('../modules/auth/screens/RegisterScreen')));
const RecoverPasswordScreen = Loadable(
  lazy(() => import('../modules/auth/screens/RecoverPasswordScreen'))
);
const ResetPasswordScreen = Loadable(
  lazy(() => import('../modules/auth/screens/ResetPasswordScreen'))
);

const AuthRouter = () => {
  return (
    <MinimalLayout>
      <Switch>
        <NavMotion>
          <Route path="/login" component={LoginScreen} />
          <Route path="/login/:token" component={LoginWithTokenScreen} />
          <Route exact path="/registro" component={RegisterScreen} />
          <Route exact path="/recuperar-clave" component={RecoverPasswordScreen} />
          <Route exact path="/restablecer-clave/:token" component={ResetPasswordScreen} />
        </NavMotion>
      </Switch>
    </MinimalLayout>
  );
};

export default AuthRouter;
