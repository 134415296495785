import { Grid } from '@material-ui/core';
import { CartItem } from '../../../../../types';
import CartGridItem from './CartGridItem';

interface CardGridProps {
  cartItems: CartItem[];
  handleItemDelete: any;
}

const CartGrid = ({ cartItems, handleItemDelete }: CardGridProps) => {
  return (
    <Grid container mt={2}>
      {cartItems.map((cartItem, index) => (
        <Grid item xs={12} md={6} key={index}>
          <CartGridItem cartItem={cartItem} handleItemDelete={handleItemDelete} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CartGrid;
