import { Button, Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { mainApi } from '../../../../../api/mainApi';
import LoadingScreen from '../../../../shared/screens/LoadingScreen';
import tipoPagoIcon from '../../../../../assets/images/tipo_pago.png';
import ArrowBack from '@material-ui/icons/ArrowBack';

import payCard from '../../../../../assets/images/pay-card.png';
import { PayContext } from '..';

const Tarjeta = () => {
  const [data, setData] = useState({
    loading: true,
    listaTipoPago: [],
  });

  useEffect(() => {
    mainApi.get('listTpWeb').then((res) => {
      if (res?.data) {
        setData((prev) => ({
          ...prev,
          loading: false,
          listaTipoPago: res.data,
        }));
      }
    });
  }, []);

  const { tipoPago, setTipoPago, setFormaPago, isHabilitadoDepositTransfer } =
    useContext<any>(PayContext);

  const { loading, listaTipoPago } = data;

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid item container xs={12} justifyContent="center">
          {isHabilitadoDepositTransfer && (
            <>
              <Grid item xs={12} mt={1}>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBack sx={{ fontSize: '2em' }} />}
                  sx={{ fontWeight: 600, fontSize: '1.2em' }}
                  onClick={() => setFormaPago(0)}
                >
                  &nbsp;&nbsp;Elegir otra forma de pago
                </Button>
              </Grid>

              <Grid item container justifyContent="center" alignItems="center" gap="1em" xs={12}>
                <Typography variant="h3" color="secondary">
                  Crédito o débito
                </Typography>
                <img src={payCard} alt="" />
              </Grid>
            </>
          )}

          <Grid item container justifyContent="center" xs={12} mt={3}>
            <img src={tipoPagoIcon} alt="" />
          </Grid>
          <Grid item xs={6} mt={3}>
            <InputLabel sx={{ color: 'black', fontWeight: 'bold', mb: 1 }}>
              Seleccione la forma de pago:
            </InputLabel>
            <TextField
              select
              value={tipoPago}
              onChange={({ target }) => setTipoPago(parseInt(target.value))}
              fullWidth
            >
              {listaTipoPago.map((option: any) => (
                <MenuItem value={parseInt(option.form_dife_valor)} key={option.form_dife_id}>
                  {option.form_dife_nomb}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Tarjeta;
