import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import Logo from '../../../components/Logo';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to="/home">
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
