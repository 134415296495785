import { useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { showConfirmationAlert } from '../../../../helpers/alerts';
import { saveProductOnDB, transformItem } from '../../../../helpers/products';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { CartItem } from '../../../../types';
import { loginWithToken } from '../../../auth/actions/authActions';
import { addToCart, updateItem } from '../../../shared/actions/cartActions';
import LoadingScreen from '../../../shared/screens/LoadingScreen';

const AddToCart = () => {
  const params = useParams<any>();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const { loading, items } = useAppSelector((state) => state.cart);

  const location = useLocation();

  useEffect(() => {
    dispatch({ type: 'cart/load' });
    const product = transformItem(params);
    try {
      // eslint-disable-next-line eqeqeq
      if (items.find((item: CartItem) => item.prod_id == params.prod_id)) {
        showConfirmationAlert(
          'Este producto ya se encuentra en el carrito',
          '¿Desea actualizarlo?'
        ).then((result) => {
          if (result.isConfirmed) {
            if (auth.isAuth) {
              saveProductOnDB(product).then((res) => dispatch(updateItem(product)));
            } else {
              dispatch(updateItem(product));
            }
          } else {
            const searchParams = new URLSearchParams(location.search);
            const token = searchParams.get('token');
            //console.log('token');
            if (!auth.isAuth && token != null) {
              dispatch(loginWithToken(token));
              dispatch({ type: 'cart/stop-load' });
            }
          }
        });
      } else {
        if (auth.isAuth) {
          saveProductOnDB(product).then((res) => dispatch(addToCart(product)));
        } else {
          dispatch(addToCart(product));
          const searchParams = new URLSearchParams(location.search);
          const token = searchParams.get('token');
          //console.log('token');
          if (token != null) {
            dispatch(loginWithToken(token));
          }
        }
      }
    } catch (error) {
      //console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{loading ? <LoadingScreen /> : <Redirect to="/carrito" />}</>;
};

export default AddToCart;
