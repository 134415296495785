import { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';

// redux hooks
import { useAppSelector } from '../../hooks/useRedux';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { isAuth } = useAppSelector((state) => state.auth);

  const location = useLocation();
  localStorage.setItem('lastPath', location.pathname);

  return (
    <Route
      {...rest}
      render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
