import { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import time from '../../../../../helpers/time';
import { urlWeb } from '../../../../../api/mainApi';

const DebtsTable = ({ data, setChecked }: any) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const initialPerPage = 10;

  useEffect(() => {
    setPage(0);
  }, [perPage]);

  const count = data.length;
  const init = page * perPage;
  const end = page === Math.ceil(count / perPage) - 1 ? count : init + perPage;

  const ref = useRef(null);

  return (
    <>
      <TableContainer>
        <Table
          ref={ref}
          sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 12px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Estado</TableCell>
              <TableCell>Items / Suscripciones</TableCell>
              <TableCell>Fecha de vencimiento</TableCell>
              <TableCell>Valor de la deuda</TableCell>
              <TableCell>
                <Checkbox
                  color="secondary"
                  onChange={({ target }) => setChecked('0', target.checked)}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(init, end).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  <Chip label="Pendiente" sx={{ background: '#F44336', color: 'white' }} />
                </TableCell>
                <TableCell>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <img
                        src={`${urlWeb}/img/icono_${item.prod_id}.png`}
                        alt=""
                        width="50px"
                        style={{
                          borderRadius: '12px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h4" color="secondary">
                        {item.prod_nomb}
                      </Typography>
                      <Typography>{item.prod_plan_nombre}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  {time(item.json.fecha_vencimiento, 'DD/MM/YYYY').format('YYYY/MM/DD')}
                </TableCell>
                <TableCell>{item.total}</TableCell>
                <TableCell>
                  <Checkbox
                    color="secondary"
                    checked={item.checked}
                    onChange={({ target }) => {
                      //console.log(target.checked);
                      setChecked(item.id_contifico, target.checked);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[initialPerPage - 5, initialPerPage, initialPerPage + 5]}
        component="div"
        count={count}
        rowsPerPage={perPage}
        page={page}
        onRowsPerPageChange={(e) => {
          setPerPage(Number(e.target.value));
        }}
        onPageChange={(_, newPage) => setPage(newPage)}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${count}`}
        nextIconButtonProps={{
          disabled: page === Math.ceil(count / perPage) - 1 || count === 0,
        }}
        backIconButtonProps={{ disabled: page === 0 || count === 0 }}
      />
    </>
  );
};

export default DebtsTable;
