import { Route, Switch } from 'react-router';
import GeneralLayout from '../../shared/layout/GeneralLayout';
import AdminPayments from '../Payments/AdminPayments';
import EditarDatos from '../Profile/Datos';
import Summary from '../Summary';
import Transactions from '../Transactions';

export const path = ['/resumen', '/transacciones', '/mis-deudas', '/perfil/editar', '/refund'];

const GeneralRoutes = () => {
  return (
    <GeneralLayout>
      <Switch>
        <Route exact path="/resumen" component={Summary} />
        <Route exact path="/transacciones" component={Transactions} />
        <Route exact path="/perfil/editar" component={EditarDatos} />
        <Route exact path="/refund" component={AdminPayments} />
      </Switch>
    </GeneralLayout>
  );
};

export default GeneralRoutes;
