import React from 'react';
import { Link } from 'react-router-dom';
// import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { LinkTarget, NavItemType } from '../../../../../interfaces/material-ui.interfaces';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../../../../../../../store/reducer';
import { CustomizationState } from '../../../../../../../types';
import { setMenuOpen, setSidebarOpened } from '../../../../../actions/customizationActions';

// style constant
const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: '18px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  menuIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  listCustomIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  listCustomIconSub: {
    width: '6px',
    height: '6px',
  },
  listCustomIconSubActive: {
    width: '8px',
    height: '8px',
  },
  listItem: {
    marginBottom: '5px',
    alignItems: 'center',
  },
  listItemNoBack: {
    marginBottom: '5px',
    backgroundColor: 'transparent !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'flex-start',
  },
  selected: {},
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
    color: theme.palette.secondary.main,
  },
}));

//-----------------------|| SIDEBAR MENU LIST ITEMS ||-----------------------//

export interface NavItemProps {
  item: NavItemType;
  level: number;
  color?: string;
}

const NavItem = ({ item, level, color }: NavItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const customization = useAppSelector((state) => state.customization);
  const customization = useSelector<IReducer, CustomizationState>((state) => state.customization);
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const Icon = item?.icon!;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      className={
        customization.isOpen === item?.id
          ? classes.listCustomIconSubActive
          : classes.listCustomIconSub
      }
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemIconClass = item?.icon ? classes.listIcon : classes.menuIcon;
  /**
   *nav-dark doesnt exist on navType
   *navType can only be 'light' | 'dark'
   */

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  itemIconClass =
    customization.themeMode === 'dark'
      ? [itemIconClass, classes.listCustomIcon].join(' ')
      : itemIconClass;

  let listItemProps: {
    component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: React.forwardRef((props, ref) => (
      <Link {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    // dispatch({ type: MENU_OPEN, id: id });

    dispatch(setMenuOpen(id));

    // matchesSM && dispatch({ type: SET_MENU, opened: false });

    matchesSM && dispatch(setSidebarOpened(false));
  };

  // active menu item on page load
  React.useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      // dispatch({ type: MENU_OPEN, id: item.id });

      dispatch(setMenuOpen(item.id!));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItem
      {...listItemProps}
      disabled={item.disabled}
      className={level > 1 ? classes.listItemNoBack : classes.listItem}
      sx={{
        borderRadius: customization.borderRadius + 'px',
        '&:hover': {
          color,
        },
      }}
      selected={customization.isOpen === item.id}
      onClick={() => itemHandler(item.id!)}
      button
      classes={{ selected: classes.selected }}
      style={{ paddingLeft: level * 23 + 'px' }}
    >
      <ListItemIcon
        className={itemIconClass}
        style={{ minWidth: level > 1 ? 18 : 36 }}
        sx={{ color: customization.isOpen === item.id ? color : 'inherit' }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={customization.isOpen === item.id ? 'h5' : 'body1'}
            color={customization.isOpen === item.id ? color : 'inherit'}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              className={classes.subMenuCaption}
              color="red"
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItem>
  );
};

export default NavItem;
