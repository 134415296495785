import { useState } from 'react';
import { Link } from 'react-router-dom';

// material ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// project imports
import AnimateButton from '../../../../../shared/components/extended/AnimateButton';
import ToggleVisibility from '../../../../../shared/components/controlled/ToggleVisibility';

// redux actions
import { login } from '../../../../../auth/actions/authActions';

// redux hooks
// import { useAppDispatch } from '../../../hooks/useRedux';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { IReducer } from '../../../../../../store/reducer';
import { AuthState } from '../../../../../../types';

const LoginSchema = Yup.object().shape({
  cedula: Yup.string()
    .max(100, 'Se admiten un máximo de 13 caracteres')
    .required('La cédula es requerida'),
  password: Yup.string()
    .max(15, 'Se admiten un máximo de 15 caracteres')
    .required('La contraseña es requerida'),
});

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const auth = useSelector<IReducer, AuthState>((state) => state.auth);

  const { values, errors, touched, getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      cedula: localStorage.getItem('rememberEmail') || '',
      password: '',
      rememberMe: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const { cedula, password, rememberMe } = values;

      // //console.log()
      dispatch(login(cedula, password));

      if (rememberMe) {
        localStorage.setItem('rememberEmail', values.cedula);
      }
    },
  });

  return (
    <>
      {auth.error && <ErrorAlert message={auth.error}></ErrorAlert>}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
          <Grid item xs={12}>
            <InputLabel htmlFor="username">Nombre de usuario</InputLabel>
            <TextField
              error={Boolean(errors.cedula && touched.cedula)}
              id="cedula"
              fullWidth
              {...getFieldProps('cedula')}
            />
            {errors.cedula && touched.cedula && (
              <FormHelperText error id="usernameError">
                {errors.cedula}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="username">Contraseña</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <ToggleVisibility
                  showPassword={showPassword}
                  handleShowPassword={handleShowPassword}
                />
              }
              error={Boolean(errors.password && touched.password)}
              fullWidth
              {...getFieldProps('password')}
            />
            {errors.password && touched.password && (
              <FormHelperText error id="passwordError">
                {errors.password}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              label="Recordar"
              control={
                <Checkbox
                  color="secondary"
                  checked={values.rememberMe}
                  {...getFieldProps('rememberMe')}
                />
              }
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component={Link}
              to="/recuperar-clave"
              color="secondary"
              sx={{ textDecoration: 'none' }}
            >
              ¿Olvidaste tu contraseña?
            </Typography>
          </Grid>
        </Grid>

        <Box mt={2} textAlign="center">
          <AnimateButton>
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: theme.palette.primary.main }}
            >
              Ingresar
            </Button>
          </AnimateButton>
          <br />
          {/* <Typography
            variant="subtitle1"
            component={Link}
            to="/registro"
            color="secondary"
            sx={{
              textDecoration: 'none',
              fontSize: '15px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            ¿No tienes cuenta? Registrate
          </Typography> */}
        </Box>
      </form>
    </>
  );
};

export default Login;
