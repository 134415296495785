import DebtsTable from './tables/DebtsTable';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  makeStyles,
  OutlinedInput,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import { User } from '../../../auth/interfaces/login.interfaces';
import time from '../../../../helpers/time';
import { getCheckout, guardarPagoDeuda } from '../../../../helpers/checkout';
import { getClientDebts } from '../../../../helpers/payments';
import { IconSearch } from '@tabler/icons';
import LoadingScreen from '../../../shared/screens/LoadingScreen';
import DatosFacturacion from '../../Cart/AuthCart/DatosFacturacion';
import Pago from '../../Cart/AuthCart/Pago';
import ArrowBack from '@material-ui/icons/ArrowBack';
import NoItems from './NoItems';
import { PayContext } from '../../Cart/AuthCart';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    color: theme.palette.text.secondary,
    fontSize: '1.5em',

    '& $alternativeLabel': {
      marginTop: 0,
    },
    '& .MuiStepLabel-label': {
      color: '#e1e1e1',
      fontSize: '.8em',
    },
    '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-completed': {
      color: `${theme.palette.secondary.main} !important`,
      fontWeight: 'bold',
    },
  },
  step: {
    '& .MuiStepIcon-text': {
      fontSize: '.6em',
      fontWeight: 'bold',
    },
    '& $completed': {
      border: 'none',
      color: theme.palette.secondary.main,
    },
    '& $active': {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: 'white',
      '& .MuiStepIcon-text': {
        fill: theme.palette.secondary.main,
      },
    },
    '& $disabled': {
      border: 'none',
      color: 'red',
    },
  },
  stepIcon: {
    color: 'white',
    border: `2px solid #e1e1e1`,
    borderRadius: '50%',
    '& .MuiStepIcon-text': {
      fill: '#e1e1e1',
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {
    color: 'red',
  },
  bar: {
    background: theme.palette.secondary.main,
    position: 'sticky',
    bottom: '20px',
  },
}));

const steps = ['Selección de deudas', 'Facturación', 'Pago'];

const MyDebts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const user: User = useAppSelector((state) => state.auth.user);

  const [deuda, setDeuda] = useState(0);
  const [debts, setDebts] = useState<{ loading: boolean; data: any[] }>({
    loading: true,
    data: [],
  });
  const [listaFacturas, setListaFacturas] = useState<any[]>([]);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getClientDebts(user.clie_id_contifico).then((debts) => {
      setDebts({
        loading: false,
        data: debts.map((pago: any) => ({ ...pago, checked: false })),
      });
      setListaFacturas(debts.map((pago: any) => ({ ...pago, checked: false })));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDeuda(debts.data.reduce((a: any, b: any) => (b.checked ? a + parseFloat(b.total) : a), 0));
  }, [debts.data, listaFacturas]);

  const setChecked = (id: string, value: boolean) => {
    if (id === '0') {
      setDebts((prev) => ({
        ...prev,
        data: prev.data.map((pago: any) => ({ ...pago, checked: value })),
      }));
      setListaFacturas((prev) => prev.map((item) => ({ ...item, checked: value })));
    } else {
      setDebts((prev) => ({
        ...prev,
        data: prev.data.map((item) =>
          item.id_contifico === id ? { ...item, checked: value } : item
        ),
      }));
      setListaFacturas((prev) =>
        prev.map((item) => (item.id_contifico === id ? { ...item, checked: value } : item))
      );
    }
  };

  const [filtros, setFiltros] = useState({
    value: '',
  });

  const handleChange = (e: any) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  const [datosFact, setDatosFact] = useState({
    clie_fact_tipo_id: user?.clie_fact_tipo_id || '',
    clie_fact_razon_social: user?.clie_fact_razon_social || '',
    clie_fact_ruc: user?.clie_fact_ruc || '',
    clie_fact_nomb_comer: user?.clie_fact_nomb_comer || '',
    clie_fact_dire: user?.clie_fact_dire || '',
    clie_fact_tele: user?.clie_fact_tele || '',
    clie_fact_correo: user?.clie_fact_correo || '',
    clie_fact_contri_espe: user?.clie_fact_contri_espe || '',
  });
  const [tipoPago, setTipoPago] = useState(0);

  const handleDataFactChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = target;
    setDatosFact((state) => ({ ...state, [id]: value }));
  };

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros]);

  const filter = () => {
    const { value } = filtros;

    const filterData = debts.data.filter((factura) => {
      let bol = false;
      if (value) {
        if (
          factura.cabe_Fact_numero.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_idenTitular.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_correoTitular.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_nombTitular.toLowerCase().includes(value.toLowerCase())
        ) {
          bol = true;

          return bol;
        } else {
          return bol;
        }
      } else {
        return true;
      }
    });
    setListaFacturas(filterData);
  };

  const handlePay = () => {
    //console.log('lo hace');
    const PaymentCheckout = getCheckout((response) => {
      guardarPagoDeuda(
        user.clie_id,
        response,
        debts.data.filter((item) => item.checked)
      ).then((res) => {
        if (res === true) {
          history.push('mis-pagos');
        }
      });
    });

    const tax_amount = +(deuda / 1.12).toFixed(2);
    const iva = +(tax_amount * 0.12).toFixed(2);

    PaymentCheckout.open({
      user_id: `${user.clie_id}`, //id delcliente
      user_email: user.clie_correo, //optional
      user_phone: user.clie_tele || '', //optional
      order_description: 'Pago web desde links.com.ec', //descripcion
      order_amount: deuda, //valor a cobrar
      order_vat: iva, //valor iva
      order_reference: `${user.clie_id};${time().format('YYYY/MM/DD').replaceAll('/', '')}`, //numero de codigo de compra (idCliente + fecha)
      order_installments_type: tipoPago,
      order_taxable_amount: tax_amount, // optional: Only available for Datafast (Equador). The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
      order_tax_percentage: 12, // optional: Only available for Datafast (Equador). The tax percentage to be applied to this order.
    });
  };

  const handleClick = () => {
    if (activeStep === 2) {
      handlePay();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Selecciona los items que deseas pagar</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <OutlinedInput
                  startAdornment={<IconSearch />}
                  name="value"
                  placeholder="Buscar"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DebtsTable data={listaFacturas} setChecked={setChecked} />
            </Grid>
          </>
        );
      case 1:
        return (
          <DatosFacturacion
            datosFact={datosFact}
            handleDataFactChange={handleDataFactChange}
            setDatosFact={setDatosFact}
          />
        );
      case 2:
        return <Pago tipoPago={tipoPago} setTipoPago={setTipoPago} />;
      default:
        return;
    }
  };

  const { loading, data } = debts;

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {data.length > 0 ? (
            <Grid container p={4}>
              <Grid item xs={12} mt={1} hidden={activeStep === 0}>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBack sx={{ fontSize: '2em' }} />}
                  sx={{ fontWeight: 600, fontSize: '1.2em' }}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  &nbsp;&nbsp;Atrás
                </Button>
              </Grid>
              <Grid item container xs={12}>
                <Stepper activeStep={activeStep} sx={{ mb: 3, width: '100%' }}>
                  {steps.map((label, index) => (
                    <Step key={index} classes={{ root: classes.step }}>
                      <StepLabel
                        classes={{
                          alternativeLabel: classes.alternativeLabel,
                          labelContainer: classes.labelContainer,
                        }}
                        StepIconProps={{
                          classes: {
                            root: classes.stepIcon,
                            completed: classes.completed,
                            active: classes.active,
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item container xs={12}>
                <PayContext.Provider
                  value={{
                    tipoPago,
                    setTipoPago,
                    isHabilitadoDepositTransfer: false,
                  }}
                >
                  {getStepContent(activeStep)}
                </PayContext.Provider>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                p={2}
                borderRadius={2}
                xs={12}
                mt={3}
                sx={{ display: deuda === 0 ? 'none' : 'flex' }}
                className={classes.bar}
              >
                <Grid item>
                  <Typography variant="h6" color="white">
                    Total a pagar:
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography variant="h5" color="white">
                    $ {deuda.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Button
                    onClick={handleClick}
                    sx={{
                      color: theme.palette.secondary.main,
                      background: 'white',
                      px: 2,
                      '&:hover': {
                        color: theme.palette.secondary.main,
                        background: '#cdcdcd',
                      },
                    }}
                  >
                    {activeStep === steps.length - 1 ? 'Pagar' : 'Siguiente'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <NoItems />
          )}
        </>
      )}
    </>
  );
};

export default MyDebts;
