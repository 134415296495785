import { Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/useRedux';
// import Terms from '../../../../auth/components/Terms';
import TermsDialog from '../../../../shared/components/TermsDialog';
import Login from './Login';
import Signup from './Signup';

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: '#e1e1e1',
    '& .MuiTab-root': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

const getTabContent = (activeTab: number) => {
  switch (activeTab) {
    case 0:
      return <Signup />;
    case 1:
      return <Login />;
    default:
      return null;
  }
};

const Auth = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();

  const handleOpenTerms = () => {
    dispatch({ type: 'setOpenTerms', payload: true });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(_, value) => setActiveTab(value)}
          className={classes.tab}
          variant="fullWidth"
        >
          <Tab value={0} label="Crea una cuenta" />
          <Tab value={1} label="Inicia sesión" />
        </Tabs>
      </Grid>
      <Grid item xs={12} p={3}>
        {getTabContent(activeTab)}
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography
          align="center"
          sx={{
            '&: hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          onClick={handleOpenTerms}
        >
          Términos y condiciones
        </Typography>
      </Grid>
      <TermsDialog />
    </Grid>
  );
};

export default Auth;
