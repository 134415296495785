import React from 'react';

// material-ui
import { Fab, List, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { GenericCardProps } from '../../../../../interfaces/material-ui.interfaces';
import { useAppSelector, useAppDispatch } from '../../../../../../../hooks/useRedux';

import { generalMenuItems, adminMenuItems } from '../../../../../menu-items';
import HelpIcon from '@material-ui/icons/Help';

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    permissionKey?: string;
  };
}

const NavGroup = () => {
  // const { user } = useAppSelector((state) => state.auth);
  const profile = useAppSelector((state) => state.profile);
  const customization = useAppSelector((state) => state.customization);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // const currentRol = user.roles[0].id;

  const filterItems = profile.id === 1 ? adminMenuItems.children : generalMenuItems.children;

  // menu list collapse & items
  const items = filterItems?.map((menu) => {
    // if (canShow(menu.roles)) {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
    // }
  });

  const handleOpenTerms = () => {
    dispatch({ type: 'setOpenTerms', payload: true });
  };

  return (
    <React.Fragment>
      <List>
        {items}
        <ListItem
          sx={{
            borderRadius: customization.borderRadius + 'px',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          button
          onClick={handleOpenTerms}
        >
          <ListItemText>Términos y condiciones</ListItemText>
        </ListItem>
        <ListItem
          sx={{
            borderRadius: customization.borderRadius + 'px',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          button
          onClick={handleOpenTerms}
        >
          <Fab
            color="secondary"
            aria-label="ayuda"
            variant="extended"
            sx={{
              position: 'fixed',
              bottom: '30px',
              left: '30px',
              zIndex: 100000,
              color: '#1EE5D5',
              fontWeight: 'bold',
            }}
          >
            <HelpIcon />
            &nbsp; Ayuda
          </Fab>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export default NavGroup;
