import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getProduct } from '../../../../../helpers/products';
import { CartItem as ICartItem, Product } from '../../../../../types';
import CancelIcon from '@material-ui/icons/Cancel';
import { deleteItem } from '../../../../shared/actions/cartActions';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { urlWeb } from '../../../../../api/mainApi';

interface Props {
  cartItem: ICartItem;
}

const CartItem = ({ cartItem }: Props) => {
  const dispatch = useAppDispatch();
  const [product, setProduct] = useState<{
    loading: boolean;
    data: Product | null;
  }>({
    loading: true,
    data: null,
  });
  useEffect(() => {
    getProduct(cartItem).then((product) => {
      setProduct((prev) => ({
        ...prev,
        loading: false,
        data: product,
      }));
    });
  }, [cartItem]);

  //console.log(product);
  const { loading, data } = product;

  const handleDelete = () => {
    dispatch(deleteItem(cartItem));
  };

  return (
    <>
      {loading ? (
        <Grid item container justifyContent="center" xs={12}>
          <CircularProgress></CircularProgress>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <img
              src={`${urlWeb}/img/icono_${cartItem.prod_id}.png`}
              alt=""
              width="60px"
              style={{ borderRadius: '12px' }}
            />
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Typography color="secondary" fontWeight={700} variant="h5">
                {data?.plan?.prod_nombre}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{data?.plan?.prod_plan_nombre}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} textAlign="center">
            <Typography variant="h3" color="#646B70">
              $ {data?.plan?.carri_total}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleDelete}>
              <CancelIcon sx={{ fill: '#9f9f9f' }} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default CartItem;
