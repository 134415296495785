import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// import { resetCustomization } from '../../shared/reducers/customizationReducer';
// import {
//   // resetProfile,
//   saveProfiles,
//   setCurrentProfile,
//   setCurrentRol,
// } from '../../shared/reducers/accessReducer';

// import { mapUserProfiles } from '../helpers';
// import { getProtocol } from '../../../helpers/urlHelpers';

import { mainApi } from '../../../api/mainApi';

import { AuthError } from '../../../types';
import { LoginResponse } from '../interfaces/login.interfaces';
// import { VerifyResponse } from '../interfaces/verify.interfaces';
import { LogoutResponse } from '../interfaces/logout.interfaces';
import { TenantsResponse } from '../../shared/interfaces/tenants.interfaces';
import { transformUser } from '../../../helpers/user';
import { clearCart } from '../../shared/actions/cartActions';
import { showAlert, showLoadingAlert } from '../../../helpers/alerts';
import Swal from 'sweetalert2';
import { getClientCart } from '../../../helpers/cart';

// interface SignInCredentials {
//   username: string;
//   password: string;
// }

export const register = (form: any) => async (dispatch: any) => {
  try {
    showLoadingAlert('Registrando usuario...');
    const resp = await mainApi.post('/RegistroUsuario', {
      ...form,
    });

    if (resp?.data?.status === '201') {
      // return loginUser(data, dispatch);
      clearSignupEmail();
      window.location.href = '/login';
      showAlert('¡Registro exitoso!', 'Su usuario ha sido creado correctamente', 'success');
    } else {
      showAlert('¡Error!', resp?.data?.msg, 'error');
      dispatch({ type: 'auth/error', payload: resp?.data.msg });
    }
  } catch (error) {
    //console.log(error);
  }
};

export const login = (cedula: string, password: string) => async (dispatch: any) => {
  try {
    const { data } = await mainApi.post<LoginResponse>('/Login', {
      cedula,
      password,
    });

    if (data.status === '200') {
      return loginUser(data, dispatch);
    } else {
      dispatch({ type: 'auth/error', payload: data.msg });
    }
  } catch (error: any) {
    dispatch({ type: 'auth/error', payload: 'Error al iniciar sesion' });
    //console.log(error);
  }
};

export const loginWithToken = (token: string) => async (dispatch: any) => {
  try {
    //console.log('hola');
    const { data } = await mainApi.post<any>('/loginTokenRedlinks', {
      token: atob(token),
    });

    if (data.status === '200') {
      //console.log(data.data[0]);

      return loginUser({ usuario: data.data[0], token: atob(token) }, dispatch);
    } else {
      dispatch({ type: 'auth/error', payload: data.msg });
    }
  } catch (error: any) {
    dispatch({ type: 'auth/error', payload: 'Error al iniciar sesion' });
  }
};

export const setIsLoggingWithToken = (isLoggingWithToken: boolean) => (dispatch: any) => {
  dispatch({ type: 'auth/setIsLoggingWithToken', payload: isLoggingWithToken });
};

const loginUser = (data: any, dispatch: any) => {
  const user = transformUser(data.usuario);

  //console.log(user);
  localStorage.setItem('x-token', `Bearer ${data.token}`);
  localStorage.setItem('user_id', JSON.stringify(user.clie_id));

  const cart = JSON.parse(localStorage.getItem('cart') || '[]');

  if (cart.length > 0) {
    mainApi
      .post('AgregarCarritoMasivo', { clie_id: user.clie_id, json: JSON.stringify(cart) })
      .then((res) => {
        return dispatch({
          type: 'auth/login',
          payload: user,
        });
      });
  } else {
    return dispatch({
      type: 'auth/login',
      payload: user,
    });
  }
};

export const updateUser = (data: any) => async (dispatch: any) => {
  showLoadingAlert();
  const clie_id = localStorage.getItem('user_id');

  mainApi
    .post('editarCliente', { ...data, clie_id })
    .then((res) => {
      if (res?.data?.status === '200') {
        dispatch({ type: 'auth/error', payload: '' });
        dispatch({ type: 'auth/update', payload: data });
        showAlert(
          '¡Actualización exitosa!',
          'Sus datos han sido actualizados correctamente',
          'success'
        );
        return true;
      } else {
        dispatch({ type: 'auth/error', payload: res.data.msg });
        Swal.close();
        return false;
      }
    })
    .catch((err) => {
      dispatch({ type: 'auth/error', payload: 'Error al actualizar los datos del usuario' });
      Swal.close();
      return false;
    });
};

export const updateUserDataFact = (data: any) => async (dispatch: any) => {
  const clie_id = localStorage.getItem('user_id');

  showLoadingAlert('Guardando datos de facturación...');

  mainApi
    .post('editarClienteDtFact', { ...data, clie_id })
    .then((res) => {
      if (res?.data?.status === '200') {
        dispatch({ type: 'auth/error', payload: '' });
        dispatch({ type: 'auth/update', payload: data });
        showAlert(
          '¡Actualización exitosa!',
          'Sus datos de facturación han sido actualizados correctamente',
          'success'
        );
        return true;
      } else {
        dispatch({
          type: 'auth/error',
          payload: 'Error al actualizar los datos de facturación del usuario',
        });
        showAlert('¡Error!', 'Error al actualizar los datos de facturación del usuario', 'error');
        return false;
      }
    })
    .catch((err) => {
      dispatch({
        type: 'auth/error',
        payload: 'Error al actualizar los datos de facturación del usuario',
      });
      showAlert('¡Error!', 'Error al actualizar los datos de facturación del usuario', 'error');
      return false;
    });
};

export const verifySession = () => async (dispatch: any) => {
  try {
    const token = localStorage.getItem('x-token');

    if (token) {
      const clie_id = localStorage.getItem('user_id') || '';
      //console.log(clie_id);
      const { data } = await mainApi.post(`verCliente`, { id: parseInt(clie_id) });
      // const roles = JSON.parse(user).roles;

      const items = await getClientCart(parseInt(clie_id));
      dispatch({ type: 'cart/set-items', payload: items });
      dispatch({ type: 'auth/verify', payload: transformUser(data[0]) });
    } else {
      dispatch({ type: 'auth/unverify' });
    }
  } catch (error) {
    //console.log(error);
  }
};

export const getApiUrl = createAsyncThunk<string, unknown, { rejectValue: AuthError }>(
  'auth/apiUrl',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get<TenantsResponse>(
        `http://${process.env.REACT_APP_LANDLORD_API_URL}/api/tenants?search=${window.location.origin}`
      );

      localStorage.setItem('apiUrl', data.domain);

      return data.domain;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.detail || error.message,
      });
    }
  }
);

export const logouts = () => async (dispatch: any) => {
  try {
    if (localStorage.getItem('x-token')) {
      dispatch({ type: 'logout' });
      localStorage.removeItem('x-token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('lastPath');
      dispatch(clearCart());
      dispatch(verifySession());
      await mainApi.post<LogoutResponse>('/logout');
    }
  } catch (error: any) {
    //console.log(error.message);
  } finally {
    // reset other states
    // thunkAPI.dispatch(resetProfile());
    // thunkAPI.dispatch(resetCustomization());
    // dispac;
  }
};

export const setSignupEmail = (email: string) => async (dispatch: any) => {
  localStorage.setItem('signupEmail', email);
  dispatch({ type: 'auth/setSignupEmail', payload: email });
};
export const clearSignupEmail = () => async (dispatch: any) => {
  localStorage.removeItem('signupEmail');
  dispatch({ type: 'auth/clearSignupEmail' });
};
