import dayjs from 'dayjs';
import 'dayjs/locale/es';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const time = dayjs;
time.extend(customParseFormat);

time.extend(LocalizedFormat);
time.locale('es');

export default time;
