import { Grid, Typography } from '@material-ui/core';
import noProductsIcon from '../../../../../assets/images/no_products.png';

const NoItems = () => {
  return (
    <Grid container justifyContent="center" p={10} spacing={1}>
      <Grid item container justifyContent="center" xs={12} md={7}>
        <img src={noProductsIcon} alt="" />
      </Grid>
      <Grid item xs={12} md={7} justifyContent="center" textAlign="center" mt={2}>
        <Typography variant="h4">¡Está vacío!</Typography>
      </Grid>
      <Grid item xs={12} md={7} textAlign="center">
        <Typography variant="body1">No tienes deudas pendientes</Typography>
      </Grid>
      {/* <Grid item xs={12} md={7} textAlign="center" mt={4}>
        <Button variant="contained" color="secondary" component="a" href="#" target="_blank">
          Continuar comprando
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default NoItems;
