import { User } from '../modules/auth/interfaces/login.interfaces';
import { mainApi } from '../api/mainApi';

export const transformUser = (user: User) => {
  const clie_id = typeof user.clie_id === 'string' ? parseInt(user.clie_id) : user.clie_id;
  const clie_estado =
    typeof user.clie_estado === 'string' ? parseInt(user.clie_estado) : user.clie_estado;
  const clie_fact_id =
    typeof user.clie_fact_id === 'string' ? parseInt(user.clie_fact_id) : user.clie_fact_id;
  const clie_fact_tipo_id =
    typeof user.clie_fact_tipo_id === 'string'
      ? parseInt(user.clie_fact_tipo_id)
      : user.clie_fact_tipo_id;
  const clie_tipo_id =
    typeof user.clie_tipo_id === 'string' ? parseInt(user.clie_tipo_id) : user.clie_tipo_id;
  const esta_id = typeof user.esta_id === 'string' ? parseInt(user.esta_id) : user.esta_id;
  const resp = typeof user.resp === 'string' ? parseInt(user.resp) : user.resp;
  const tipo_identi_id =
    typeof user.tipo_identi_id === 'string' ? parseInt(user.tipo_identi_id) : user.tipo_identi_id;

  return {
    ...user,
    clie_id,
    clie_estado,
    clie_fact_id,
    clie_fact_tipo_id,
    clie_tipo_id,
    esta_id,
    resp,
    tipo_identi_id,
  };
};

export const changeUserPassword = async (clie_id: number, clave: string, clave_nueva: string) => {
  const resp = await mainApi.post('editarClienteClave', { clie_id, clave, clave_nueva });

  if (resp?.data) {
    if (resp?.data?.status === '200') {
      return {
        status: 'success',
        message: 'La contraseña se actualizó correctamente',
      };
    } else if (resp?.data?.status === '401') {
      return {
        status: 'error_message',
        message: resp?.data?.msg,
      };
    } else {
      return {
        status: 'error_message',
        message: 'Ocurrió un error al actualizar la contraseña',
      };
    }
  } else {
    return {
      status: 'error',
      message: 'Error al cambiar la contraseña',
    };
  }
};
