import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setSignupEmail } from '../../../../../auth/actions/authActions';

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [correo, setCorreo] = useState('');

  const handleClick = () => {
    dispatch(setSignupEmail(correo));
    history.push('/registro');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel>Correo electrónico</InputLabel>
        <TextField
          name="correo"
          value={correo}
          fullWidth
          onChange={({ target }) => setCorreo(target.value)}
          sx={{ mt: 1 }}
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <Button variant="contained" color="secondary" fullWidth onClick={handleClick}>
          Continuar
        </Button>
      </Grid>
    </Grid>
  );
};

export default Signup;
