import { BrowserRouter } from 'react-router-dom';

// material-ui
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import AppRouter from './router/AppRouter';

// project imports
import NavigationScroll from './modules/shared/layout/NavigationScroll';

// redux hooks

// default theme
import theme from './modules/shared/themes';
import { IReducer } from './store/reducer';
import { CustomizationState } from './types';
import { useSelector } from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-multi-carousel/lib/styles.css';

import 'animate.css';

const App = () => {
  const customization = useSelector<IReducer, CustomizationState>((state) => state.customization);

  /**
   * BrowserRouter
   * this HOC wrap to entire app to provide routing service
   */
  return (
    <BrowserRouter>
      {/**
       * Material UI HOCs
       * this HOCs wrap to entire app to provide styles and animations
       */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {/**
             * AppRouter
             * this HOC declare all application routes
             * based on Auth status an user roles
             */}
            <AppRouter />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

export default App;
