import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { IconSearch } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { getAllPayments } from '../../../../helpers/payments';
import PaymentsTable from './PaymentsTable';
import LoadingScreen from '../../../shared/screens/LoadingScreen';

const AdminPayments = () => {
  const [facturas, setFacturas] = useState<{ loading: boolean; data: any[] }>({
    loading: true,
    data: [],
  });
  const [filtros, setFiltros] = useState({
    value: '',
    fecha_desde: '',
    fecha_hasta: '',
  });
  const [listaFacturas, setListaFacturas] = useState<any[]>([]);

  useEffect(() => {
    getAllPayments().then((facturas) => {
      setFacturas({ loading: false, data: facturas });
      setListaFacturas(facturas);
    });
  }, []);

  const { loading, data } = facturas;

  const handleChange = (e: any) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  const filter = () => {
    const { value, fecha_desde, fecha_hasta } = filtros;

    const filterData = data.filter((factura) => {
      //console.log(factura.cabe_Fact_reg);
      //console.log(factura.cabe_Fact_reg >= fecha_desde);
      let bol = false;
      if (value || fecha_desde || fecha_hasta) {
        if (
          factura.cabe_Fact_numero.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_idenTitular.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_correoTitular.toLowerCase().includes(value.toLowerCase()) ||
          factura.cabe_Fact_nombTitular.toLowerCase().includes(value.toLowerCase())
        ) {
          bol = true;
          if (fecha_desde) {
            if (factura.cabe_Fact_reg.split(' ')[0] >= fecha_desde) {
              bol = true;
            } else {
              bol = false;
            }
          }
          if (fecha_hasta) {
            if (factura.cabe_Fact_reg.split(' ')[0] <= fecha_hasta) {
              bol = bol && true;
            } else {
              bol = bol && false;
            }
          }
          return bol;
        } else {
          return bol;
        }
      } else {
        return true;
      }
    });
    setListaFacturas(filterData);
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Paper>
          <Grid container p={2}>
            <Grid item xs={12}>
              <Typography variant="h2">Pagos</Typography>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={3}>
                <InputLabel>Filtro</InputLabel>
                <OutlinedInput
                  startAdornment={<IconSearch />}
                  name="value"
                  placeholder="Buscar"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Fecha desde</InputLabel>
                <TextField type="date" name="fecha_desde" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Fecha hasta</InputLabel>
                <TextField type="date" name="fecha_hasta" fullWidth onChange={handleChange} />
              </Grid>
              <Grid item xs={12} md={2}>
                <InputLabel sx={{ color: 'white' }}>s</InputLabel>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: '51.13px' }}
                  fullWidth
                  onClick={filter}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PaymentsTable data={listaFacturas} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default AdminPayments;
