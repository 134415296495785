import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import bgImage from '../../../../assets/images/fondo-login.jpg';

// third-party
// project imports
// import Customization from '../Customization';
// import navigation from "./../../menu-items";

// assets
// import { IconChevronRight } from "@tabler/icons";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundImage: `url(${bgImage})`,
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(101,127,152,0.5)',
    borderRadius: '12px',
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const NoAuthLayout = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item container justifyContent="center" xs={8} md={6} lg={5} py={2}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default NoAuthLayout;
