import { mainApi } from '../api/mainApi';
import { showAlert } from './alerts';

export const getClientCart = async (clie_id: number) => {
  try {
    const { data } = await mainApi.post('/ConsultaCarritoClient', {
      clie_id,
    });

    if (data.status === '406') {
      return [];
    }
    return data;
  } catch (error) {
    showAlert('Error', 'Hubo un error al obtener los elementos del carrito', 'error');
    return [];
  }
};
